import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { security } from 'meya'
import { useUserStore } from './../store/account/user.store'
import ScopeManager from '@application/scope.manager'

import BaseRoutes from './base'
import LoginRoutes from './login'

const routes: Array<RouteRecordRaw> = [...LoginRoutes, ...BaseRoutes]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const needAuth = to.meta.auth_check === undefined || to.meta.auth_check === true
  if (!needAuth || (needAuth && security.hasSession())) {
    const userStore = useUserStore()

    // Validar primero por el scope de la ruta
    if (to.meta.scope !== undefined) {
      if (!ScopeManager.hasScope()) {
        next({ name: 'login' })
        return
      }

      // Si hay scope, validar que el usuario lo tenga asignado
      if (ScopeManager.getScope() != to.meta.scope) {
        next({ name: 'not-permission' })
        return
      }
    }

    // Validar los permisos de la ruta con los del usuario
    if (to.meta.permission !== undefined) {
      const has = userStore.hasPermission(to.meta.permission as string)
      // Es por el refrescar la pagina y el añadir una url directamente
      if ((from.fullPath == '/' && from.name === undefined) || has) {
        next()
      } else {
        next({ name: has ? 'login' : 'not-permission' })
      }
    } else {
      next()
    }
  } else {
    next({ name: 'login' })
  }
})

export default router
