<template>
  <lca-card class="p-r-20 p-l-20 m-b-20" no-action>
    <div class="notification-container">
      <div class="notification-content">
        <div>
          {{ props.notification.notification }}
        </div>
        <div>
          <b>{{ formatDate(props.notification.createdAt) }}</b>
        </div>
      </div>
      <lca-button
        v-if="!props.notification?.read"
        text
        icon="fa-solid fa-check-double"
        @click="readNotification()"
      ></lca-button>
    </div>
  </lca-card>
</template>

<script setup lang="ts">
import { UserNotificationService } from '@service/notification/userNotification/userNotification.service'
import { UserNotification } from '@entity/notification/userNotification/userNotification.entity'
import { DateTime } from 'meya'
const emit = defineEmits(['reloadNotifications'])
const props = defineProps({ notification: UserNotification })
const formatDate = (value: string) => DateTime.fromString(value).format('DD-MM-YYYY HH:mm')

const readNotification = () => {
  UserNotificationService.read(props.notification.id).then(() => {
    emit('reloadNotifications')
  })
}
</script>
<style scoped>
.notification-container {
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Opcional: centra horizontalmente */
  padding: 5px 10px; /* Mantiene el padding original */
  text-align: center; /* Centra el texto si es necesario */
}

.notification-content {
  display: flex;
  flex-direction: column;
}
</style>
