export class Criteria {
  protected searchTxt: string | null = null
  protected filters: object = {}
  protected orders: object[]
  protected offset: number
  protected limit: number

  constructor(filters: object, orders: object[], offset: number, limit: number) {
    this.filters = { ...filters } // Quitamos la referencia a la reactividad
    this.orders = orders
    this.offset = offset
    this.limit = limit
  }

  public getFilters(): object {
    return this.filters
  }

  public getFilter(key: string): object | undefined {
    return this.filters[key as keyof object]
  }

  public addFilter(key: string, value: object): void {
    this.filters = { ...this.filters, [key]: value }
  }

  public removeFilter(key: string): void {
    delete this.filters[key as keyof object]
  }

  public getOrders(): object[] {
    return this.orders
  }

  public getOffset(): number {
    return this.offset
  }

  public getLimit(): number {
    return this.limit
  }

  public getSearchTxt(): string | null {
    return this.searchTxt
  }

  public setSearchTxt(searchTxt: string | null) {
    this.searchTxt = searchTxt
  }
}
