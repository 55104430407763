<template>
  <v-form ref="formRef">
    <slot></slot>
  </v-form>
</template>

<script setup lang="ts">
import { ref } from 'vue'

defineOptions({
  name: 'Form',
  inheritAttrs: false,
  customOptions: {}
})

const formRef = ref(null)
const validate = async () => await formRef.value.validate()

defineExpose({ validate })
</script>
