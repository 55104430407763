/* eslint-disable */
import { api, toast, ConfigOptions } from 'meya'
import { Criteria } from '@service/common/criteria'
import { CriteriaFilterOperator } from '@service/common/criteriaFilterOperator'

interface CriteriaOrderObj {
  key: string
  order: string
}

interface ApiError {
  message: string
  response?: {
    data?: {
      message: string
      transaction_id: string
      type?: string
    }
  }
}

export interface SearchResponse {
  total: number
  items: []
}

export abstract class BaseService {
  protected static apiDefaultSearchPath: string = ''
  protected static apiParamsPath: Record<string, string> | null = null

  //public static showError: boolean = true

  protected static get(path: string, options?: ConfigOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .get(path, options)
        .then((data: any) => resolve(data))
        .catch((error: Error) => this.showAPIError(error, reject, options))
    })
  }

  protected static post(path: string, data: any = {}, options?: ConfigOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .post(path, data, options)
        .then((data: any) => resolve(data))
        .catch((error: Error) => this.showAPIError(error, reject))
    })
  }

  protected static put(path: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .put(path, data)
        .then((data: any) => resolve(data))
        .catch((error: Error) => this.showAPIError(error, reject))
    })
  }

  protected static delete(path: string, data: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .delete(path, data)
        .then((data: any) => resolve(data))
        .catch((error: Error) => this.showAPIError(error, reject))
    })
  }

  protected static criteriaToApi(criteria: Criteria): object {
    return {
      filters: this.transformFiltersCriteria(criteria),
      orders: this.transformOrderCriteria(criteria.getOrders() as CriteriaOrderObj[]),
      pagination: {
        offset: criteria.getOffset(),
        limit: criteria.getLimit()
      }
    }
  }

  public static prepareSearch(params: Record<string, string> | null) {
    this.apiParamsPath = params
    return this
  }

  public static search(criteria: Criteria, options?: ConfigOptions): Promise<SearchResponse> {
    let path = this.apiDefaultSearchPath
    if (this.apiParamsPath) {
      path = path.replace(/{(\w+)}/g, (_, key) => {
        return this.apiParamsPath && this.apiParamsPath[key] !== undefined ? this.apiParamsPath[key] : `{${key}}`
      })
    }
    this.apiParamsPath = null

    return this.post(path, this.criteriaToApi(criteria), options).then(data => data as SearchResponse)
  }

  protected static transformFiltersCriteria(criteria: Criteria): object[] {
    const filters = criteria.getFilters()
    let search = criteria.getSearchTxt()
    search = search ? search.trim() : null

    const result: object[] = []
    const global: object[] = []
    Object.keys(filters).forEach((key: string) => {
      const _filter = (filters as any)[key]
      const fieldName = _filter.field ?? key

      if (_filter.value && _filter.value != null) {
        if (!(typeof _filter.value == 'string' && _filter.value.trim().length <= 0)) {
          result.push({
            field: fieldName,
            operator: _filter.operator,
            value: _filter.value
          })
        }
      }

      // Logica del buscador generico
      if (_filter.global === true && search) {
        global.push({
          field: fieldName,
          operator: CriteriaFilterOperator.CONTAINS,
          value: search
        })
      }
    })

    if (global.length === 1) {
      result.push(global[0])
    } else if (global.length > 0) {
      result.push({
        field: 'or',
        operator: CriteriaFilterOperator.OR,
        value: global
      })
    }
    return result
  }

  protected static transformOrderCriteria(orders: CriteriaOrderObj[]): object[] {
    return orders.map((order: CriteriaOrderObj) => {
      return {
        order_by: order.key,
        order: order.order
      }
    })
  }

  protected static showAPIError(error: ApiError, reject?: Function, options?: ConfigOptions): void {
    let message = error.message
    if (error?.response?.data && error?.response?.data?.message) {
      message = '<div>' + error?.response?.data?.message + '</div>'
      if (error?.response?.data?.transaction_id) {
        message += '<div class="toast-transaction-id">' + error?.response?.data?.transaction_id + '</div>'
      }
    }

    if (message === '__TOKEN__CANCEL__') {
      return
    }

    if (!options?.hideError) {
      toast.error(message)
    }

    reject!(error.message)
  }
}
