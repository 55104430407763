import { RouteRecordRaw } from 'vue-router'

const paths: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    meta: { auth_check: false },
    component: () => import('@views/login/LoginView.vue')
  },
  {
    path: '/user/recovery/:token',
    name: 'recovery',
    meta: { auth_check: false },
    component: () => import('@views/login/RecoveryView.vue')
  }
]

export default paths
