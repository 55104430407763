import SettingRoutes from './modules/setting'
import ModuleRoutes from './modules/module'
import EmployeeRoutes from './modules/employee'
import StudentRoutes from './modules/student'
import PromotionRoutes from './modules/promotion'
import ModulePracticeRoutes from './modules/modulePractice'
import PaymentOrderRoutes from './modules/paymentOrder'
import ManualRoutes from './modules/manual'
import LeadRoutes from './modules/leads'
import { ScopeEnum } from '@entity/common/login/scope.enum'

export default [
  {
    path: 'backoffice',
    name: 'backoffice-base',
    meta: { scope: ScopeEnum.BACKOFFICE },
    component: () => import('@vBackoffice/BackofficeView.vue'),
    children: [
      {
        path: 'home',
        name: 'backoffice-home',
        component: () => import('@vBackoffice/dashboard/DashboardView.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@vBackoffice/profile/ProfileView.vue')
      },
      ...ModuleRoutes,
      ...SettingRoutes,
      ...EmployeeRoutes,
      ...StudentRoutes,
      ...PromotionRoutes,
      ...ModulePracticeRoutes,
      ...PaymentOrderRoutes,
      ...ManualRoutes,
      ...LeadRoutes
    ]
  }
]
