import { ApiClass, type CancelTokenSource, type ConfigOptions } from './api.class'
export const api = new ApiClass(process.env.VUE_APP_API ?? '')
export type { CancelTokenSource, ConfigOptions }

/* import DateClass from './date.class'
export const date = (date:string|Date|null|undefined) => new DateClass( date ) */

import SecurityClass from './security.class'
export const security = new SecurityClass()

import DeviceClass from './device.class'
export const device = new DeviceClass()
