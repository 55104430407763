export default {
  dark: false,
  colors: {
    primary: '#009E7F',
    secondary: '#3E444D',
    sidebar: '#343135',
    sidebarFilters: '#f1f8f3'
  }
}

// #009E7F -> VERDE
// #A1A8AD -> GRIS CLARO
// #3E444D -> GRIS OSCURO
