const SESSION_FIELD: string = '_APP_U_8K28__'

export default class SecurityClass {
  hasSession(): boolean {
    let token = this.getSession()
    return token != null
  }

  getSession(): any {
    return localStorage.getItem(SESSION_FIELD)
  }

  setSession(token: string): void {
    localStorage.setItem(SESSION_FIELD, token)
  }

  removeSession(): void {
    localStorage.removeItem(SESSION_FIELD)
  }
}
