<template>
  <v-textarea
    v-model="model"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :density="density"
    :variant="variant"
    v-bind="$attrs"
  >
    <slot></slot>
  </v-textarea>
</template>

<script setup lang="ts">
defineOptions({
  name: 'Textarea',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  label?: string
  rules?: any[]
  disabled?: boolean
  density?: 'default' | 'comfortable' | 'compact' | undefined
  variant?: 'outlined' | 'filled' | 'solo' | 'solo-inverted' | 'solo-filled' | 'plain' | 'underlined' | undefined
}

const props = withDefaults(defineProps<PropsType>(), {
  density: 'compact'
})
const model = defineModel()
</script>
