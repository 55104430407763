<template>
  <v-text-field
    v-model="model"
    :active="show"
    :focused="show"
    :label="label"
    readonly
    density="compact"
    :disabled="disabled"
    :rules="rules"
    :class="getClassComponent"
    :hide-details="icon"
  >
    <template #prepend>
      <div class="icon-picker">
        <v-icon icon="fa-solid fa-palette" :style="`opacity: 1; color: ${model}`" />
      </div>
      <v-dialog v-model="show" activator="parent" width="auto">
        <v-color-picker v-model="model" :hide-sliders="false" hide-inputs :mode="mode" :show-swatches="false" />
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

defineOptions({
  name: 'InputColorPicker',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  icon?: boolean
  label?: string
  clearable?: boolean
  disabled?: boolean
  rules?: any[]
  mode: 'rgb' | 'rgba'
  class?: string
}
const props = withDefaults(defineProps<PropsType>(), {
  icon: false,
  mode: 'rgb'
})

const model = defineModel()
const show = ref(false)

const getClassComponent = computed(() => {
  let _class = ['lca-input-color-picker']
  if (props.icon) {
    _class.push('--icon')
  }

  if (props.class) {
    _class.push(props.class)
  }

  return _class.join(' ')
})
</script>

<style lang="scss">
.lca-input-color-picker {
  &.--icon {
    &.v-input--density-compact.v-input--plain-underlined {
      display: inline-block;

      .v-input__prepend {
        padding-top: 0px;
        margin-inline-end: 0px;

        .icon-picker {
          border: 1px solid transparent;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          border-radius: 50%;
          padding: 6px;

          &:hover {
            background-color: var(--grey-hover);
            cursor: pointer;
          }
        }
      }

      &.v-input--error {
        .v-input__prepend {
          .icon-picker {
            border: 1px solid rgb(var(--v-theme-error));
          }
        }
      }
    }

    .v-input__control {
      display: none;
    }
  }
}
</style>
