import { ValueObject } from './valueObject'
import dayjs, { type QUnitType, type OpUnitType, type UnitType, type ManipulateType } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'

export class DateTime extends ValueObject<dayjs.Dayjs> {
  protected _defaultFormat = 'DD-MM-YYYY HH:mm:ss'

  constructor(value: dayjs.Dayjs) {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(isBetween)
    super(value)
  }

  static fromDate(value: Date): DateTime {
    return new DateTime(dayjs(value))
  }

  static fromString(value: string): DateTime {
    return new DateTime(dayjs(value))
  }

  static now(): DateTime {
    return new DateTime(dayjs())
  }

  public format(format?: string): string {
    return dayjs(this.value).format(format ?? this._defaultFormat)
  }

  public formatToApi(): string {
    // TODO: Revisar si hay que gestionar el timezone de otro modo
    return this.format('YYYY-MM-DDTHH:mm:ssZ')
  }

  public diff(date: DateTime, unit: QUnitType | OpUnitType): number {
    return this.value.diff(date.value, unit)
  }

  public add(value: number, unit?: ManipulateType): DateTime {
    return new DateTime(this.value.add(value, unit))
  }

  public set(unit: UnitType, value: number): DateTime {
    return new DateTime(this.value.set(unit, value))
  }

  static getTimeZone(): string {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    return dayjs.tz.guess()
  }

  // '()' excludes start and end date (default)
  // '[]' includes start and end date
  // '[)' includes the start date but excludes the stop
  public between(start: DateTime, end: DateTime, units?: OpUnitType | null, opts?: '()' | '[]' | '[)' | '(]'): boolean {
    return this.value.isBetween(start.value, end.value, units, opts)
  }
}
