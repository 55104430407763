<template>
  <v-btn v-if="icon" :icon="icon" @click="clickBtn()" :loading="loading" :size="size" density="compact"></v-btn>

  <v-file-input
    v-model="files"
    ref="lcaFileInputElement"
    :class="icon ? 'hide' : ''"
    :active="active"
    :variant="variant"
    :append-icon="appendIcon"
    :counter="counter"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :name="name"
    :rules="rules"
    :show-size="showSize"
    :disabled="disabled"
    @change="onChange"
  >
    <slot></slot>
  </v-file-input>
</template>

<script setup lang="ts">
import { ref } from 'vue'

defineOptions({
  name: 'FileInput',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  icon?: string
  active?: boolean
  variant?: 'underlined' | 'outlined' | 'filled' | 'solo' | 'solo-inverted' | 'solo-filled' | 'plain'
  appendIcon?: string
  counter?: boolean
  label?: string
  loading?: boolean
  multiple?: boolean
  name?: string
  rules?: any
  showSize?: boolean | 1000 | 1024
  disabled?: boolean
  size?: string | number //x-small, small, default, large, and x-large
}

const props = defineProps<PropsType>()
const emits = defineEmits(['change'])
const files = defineModel()

const lcaFileInputElement = ref(null)

const clickBtn = () => {
  lcaFileInputElement.value.click()
}

const onChange = () => {
  emits('change', lcaFileInputElement.value.files)
}
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
</style>
