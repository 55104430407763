<template>
  <v-date-picker v-model="model" :color="color" :class="class" :multiple="multiple">
    <template #title>
      <slot name="title"></slot>
    </template>

    <template #actions>
      <slot name="actions"></slot>
    </template>
  </v-date-picker>
</template>

<script setup lang="ts">
defineOptions({
  name: 'DatePicker',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  color?: string
  multiple?: boolean
  class?: string
}

const props = defineProps<PropsType>()
const model = defineModel()
</script>
