import { Uuid } from 'meya'
import { BaseEntity } from '@entity/common/base.entity'
import { Document, DocumentAPIResponse } from '@entity/common/document.entity'
import { ScopeEnum } from '@entity/common/login/scope.enum'

interface PermissionAPIResponse {
  id: string
  name: string
}

interface UserAPIResponse {
  id: string
  name: string
  surname: string
  email: string
  is_admin: boolean
  role_id: string
  password?: string
  permissions?: PermissionAPIResponse[]
  profile_picture_document?: DocumentAPIResponse
}

export class User extends BaseEntity {
  readonly id: Uuid
  readonly name: string
  readonly surname: string
  readonly email: string
  readonly isAdmin: boolean
  readonly roleId: string
  readonly password?: string
  private permissions: string[] = []
  private profileImage?: Document
  private scopes: ScopeEnum[] = []
  private currentScope: ScopeEnum | null = null

  constructor(
    id: Uuid,
    name: string,
    surname: string,
    email: string,
    roleId: string,
    password?: string,
    isAdmin: boolean = false
  ) {
    super()
    this.id = id
    this.name = name
    this.surname = surname
    this.email = email
    this.isAdmin = isAdmin
    this.roleId = roleId
    this.password = password
  }

  static fromAPI(data: UserAPIResponse): User {
    const user = new User(
      Uuid.fromString(data.id),
      data.name,
      data.surname,
      data.email,
      data.role_id,
      data.password,
      data.is_admin
    )
    if (data.profile_picture_document) {
      user.profileImage = Document.fromAPI(data.profile_picture_document)
    }
    if (data.permissions) {
      user.setPermissions(data.permissions.map(p => p.name))
    }
    return user
  }

  public getId(): Uuid {
    return this.id
  }

  public getSurname(): string {
    return this.surname
  }

  public getName(): string {
    return this.name
  }

  public getEmail(): string {
    return this.email
  }

  public getRoleId(): string {
    return this.roleId
  }

  public getIsAdmin(): boolean {
    return this.isAdmin
  }

  public getPassword(): string | undefined {
    return this.password
  }

  public getPermissions(): string[] {
    return this.permissions
  }

  public setPermissions(permissions: string[]): void {
    this.permissions = permissions
  }

  public getProfileImage(): Document | undefined {
    return this.profileImage
  }

  public getScopes(): ScopeEnum[] {
    return this.scopes
  }

  public setScopes(scopes: ScopeEnum[]): void {
    this.scopes = scopes
  }

  public getCurrentScope(): ScopeEnum | null {
    return this.currentScope
  }

  public setCurrentScope(scope: ScopeEnum): void {
    this.currentScope = scope
  }
}
