<template>
  <lca-dialog v-model="scopeModal" width="auto" persistent>
    <div class="scopes">
      <div class="title">{{ $t('scope.loginAs') }}</div>
      <div class="options">
        <div
          class="scope"
          @click="loginAs(ScopeEnum.BACKOFFICE)"
          v-if="hasBackofficeScope && currentScope != ScopeEnum.BACKOFFICE"
        >
          <i class="fa-solid fa-user-tie fa-3x"></i>
          <span>{{ $t('scope.backoffice') }}</span>
        </div>
        <div
          class="scope"
          @click="loginAs(ScopeEnum.EMPLOYEE)"
          v-if="hasEmployeeScope && currentScope != ScopeEnum.EMPLOYEE"
        >
          <i class="fa-solid fa-chalkboard-user fa-3x"></i>
          <span>{{ $t('scope.employee') }}</span>
        </div>
        <div
          class="scope"
          @click="loginAs(ScopeEnum.STUDENT)"
          v-if="hasStudentScope && currentScope != ScopeEnum.STUDENT"
        >
          <i class="fa-solid fa-graduation-cap fa-3x"></i>
          <span>{{ $t('scope.student') }}</span>
        </div>
      </div>
      <div class="opt-cancel">
        <lca-button type="primary" text @click="scopeModal = false">{{ $t('core.cancel') }}</lca-button>
      </div>
    </div>
  </lca-dialog>
</template>

<script setup lang="ts">
import { ScopeEnum } from '@/application/entities/common/login/scope.enum'
import { useRouter } from 'vue-router'
import { toast, useI18n } from 'meya'
import ScopeManager from '@application/scope.manager'
import { useUserStore } from '@stores/account/user.store'
import { computed } from 'vue'

const scopeModal = defineModel()
const router = useRouter()
const { t } = useI18n()
const userStore = useUserStore()

const hasBackofficeScope = computed(() => userStore.getScopes().includes(ScopeEnum.BACKOFFICE))
const hasEmployeeScope = computed(() => userStore.getScopes().includes(ScopeEnum.EMPLOYEE))
const hasStudentScope = computed(() => userStore.getScopes().includes(ScopeEnum.STUDENT))
const currentScope = computed(() => ScopeManager.getScope())

const open = () => {
  scopeModal.value = true
}

const loginAs = (scope: ScopeEnum) => {
  try {
    userStore.changeScope(scope)
    const name = ScopeManager.getScopeHome(scope)
    router.push({ name })
  } catch (e) {
    toast.error(t('scope.notScope'))
  }
}

defineExpose({
  open,
  loginAs
})
</script>

<style scoped lang="scss">
.scopes {
  padding: 25px;
  background-color: #fff;
  border-radius: $border-radius;
  text-align: center;

  .title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .options {
    display: flex;
    justify-content: space-evenly;

    .scope {
      display: flex;
      flex-direction: column;
      padding: 25px;
      border-radius: $border-radius;
      border: 1px solid var(--primary-color);
      width: 130px;

      i {
        margin-bottom: 10px;
      }

      &:hover {
        background-color: var(--grey-hover);
        cursor: pointer;
      }

      &:first-child {
        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      &:last-child {
        &:not(:first-child) {
          margin-left: 25px;
        }
      }
    }
  }

  .opt-cancel {
    margin-top: 10px;
    text-align: right;
  }
}
</style>
