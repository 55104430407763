<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center card-viewer-title" color="primary">
        <div>{{ props.name }}</div>

        <div>
          <!-- ZOOM -->
          <v-btn icon="fa-solid fa-magnifying-glass-minus" variant="text" @click="zoomLess" />
          <span class="zoom-display">{{ currentZoom }}%</span>
          <v-btn icon="fa-solid fa-magnifying-glass-plus" variant="text" @click="zoomPlus" />
        </div>

        <div>
          <v-btn icon="fa-solid fa-print" variant="text" @click="onPrint"></v-btn>
          <v-btn icon="fa-solid fa-download" variant="text" @click="onDownload"></v-btn>
          <v-btn icon="fa-solid fa-times" variant="text" class="m-l-20" @click="show = false"></v-btn>
        </div>
      </v-card-title>

      <div class="container-viewer">
        <div class="loading" v-if="!props.content?.value">
          <i class="fa-solid fa-spinner fa-spin fa-3x"></i>
        </div>

        <template v-else>
          <template v-if="isImage()">
            <ImageViewer :content="props.content.value" :zoom="currentZoom" @zoomIn="zoomPlus" @zoomOut="zoomLess" />
          </template>

          <template v-else-if="isPDF()">
            <PDFViewer :content="props.content.value" :zoom="currentZoom" @zoomIn="zoomPlus" @zoomOut="zoomLess" />
          </template>

          <template v-else>
            NO ESTOY DEFINIDO
            {{ contentFileType }}
            {{ props.content }}
            ===========
          </template>
        </template>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { BlobDocument } from '@/application/valueObjects/blobDocument'

import ImageViewer from './types/ImageViewer'
import PDFViewer from './types/PDFViewer'

defineOptions({
  name: 'DocumentViewer',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  name: string
  content?: BlobDocument
}
const props = defineProps<PropsType>()
const show = defineModel()

const zoomAvailable = [10, 20, 25, 30, 40, 50, 75, 80, 90, 100, 120, 150, 175, 200]
const zoomId = ref(5)

const contentFileType = computed(() => {
  return props.content ? props.content.value.type : null
})

const isImage = () => {
  return contentFileType.value?.startsWith('image')
}

const isPDF = () => {
  return contentFileType.value?.startsWith('application/pdf')
}

const currentZoom = computed(() => {
  return zoomAvailable[zoomId.value]
})

const maxZoom = zoomAvailable.length - 1

const zoomPlus = () => {
  if (zoomId.value < maxZoom) {
    zoomId.value++
  }
}

const zoomLess = () => {
  if (zoomId.value > 0) {
    zoomId.value--
  }
}

const onPrint = () => {
  removeOldPrintIframe()

  const content = URL.createObjectURL(props.content.value)

  let iframe = document.createElement('iframe')
  document.body.appendChild(iframe)

  iframe.id = 'lca-ifame-printer-helper'
  iframe.style.display = 'none'
  iframe.src = content
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus()
      iframe.contentWindow.print()
    }, 1)
  }
}

const removeOldPrintIframe = () => {
  let old = document.getElementById('lca-ifame-printer-helper')
  if (old) {
    old.remove()
  }
}

const onDownload = () => {
  const link = document.createElement('a')

  const content = URL.createObjectURL(props.content.value)
  link.href = content
  link.download = props.name
  document.body.append(link)
  link.click()
  link.remove()
}
</script>

<style lang="scss">
.card-viewer-title {
  background-color: $primary-color;

  .zoom-display {
    padding: 0 10px;
  }
}

.container-viewer {
  padding: 10px;
  //background-color: #e0e0e0;
  height: calc(100vh - 64px); // 64px alto del titulo
  text-align: center;
  overflow-x: auto;
  background-color: #e0e0e0;

  .loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
