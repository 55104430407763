import { Uuid } from 'meya'
import { BaseEntity } from '@entity/common/base.entity'

export interface DocumentAPIResponse {
  id: string
  name: string
  mime_type: string
  extension?: string
  size?: string
}

export class Document extends BaseEntity {
  readonly id: Uuid
  readonly name: string
  readonly mimeType: string
  readonly extension?: string
  readonly size?: string

  constructor(id: Uuid, name: string, mimeType: string, extension?: string, size?: string) {
    super()
    this.id = id
    this.name = name
    this.extension = extension
    this.mimeType = mimeType
    this.size = size
  }

  static fromAPI(data: DocumentAPIResponse): Document {
    return new Document(Uuid.fromString(data.id), data.name, data.mime_type, data.extension, data.size)
  }

  public getId(): Uuid {
    return this.id
  }

  public getName(): string {
    return this.name
  }

  public getMimeType(): string {
    return this.mimeType
  }

  public getExtension(): string | undefined {
    return this.extension
  }

  public getSize(): string | undefined {
    return this.size
  }
}
