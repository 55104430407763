const SCOPE_FIELD: string = '_APP_S_8K53__'
import { ScopeEnum } from '@entity/common/login/scope.enum'
import { RouteRecordName } from 'vue-router'

export default class ScopeManager {
  static hasScope(): boolean {
    const scope = this.getScope()
    return scope != null
  }

  static getScope(): string | null {
    return localStorage.getItem(SCOPE_FIELD)
  }

  static setScope(scope: string): void {
    // validar que el scope es uno del listado
    if (!Object.values(ScopeEnum).includes(scope as ScopeEnum)) {
      console.error('Scope not allowed 1')
      throw new Error('Scope not allowed 1')
    }

    localStorage.setItem(SCOPE_FIELD, scope)
  }

  static removeScope(): void {
    localStorage.removeItem(SCOPE_FIELD)
  }

  static getScopeHome = (scope: ScopeEnum): RouteRecordName | undefined => {
    if (scope === ScopeEnum.BACKOFFICE) {
      return 'backoffice-home'
    }

    if (scope === ScopeEnum.EMPLOYEE) {
      return 'employee-home'
    }

    if (scope === ScopeEnum.STUDENT) {
      return 'student-home'
    }

    return undefined
  }
}
