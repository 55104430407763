<template>
  <v-time-picker v-model="model" :format="format" :hide-header="hideHeader" :title="title"> </v-time-picker>
</template>

<script setup lang="ts">
defineOptions({
  name: 'TimePicker',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  format?: 'ampm' | '24hr' | undefined
  hideHeader?: boolean | undefined
  title?: string | undefined
}
const props = defineProps<PropsType>()

const model = defineModel()
</script>
