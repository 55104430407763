import { Uuid } from 'meya'
import { BaseEntity } from '@entity/common/base.entity'

export interface NoteAPIResponse {
  id: string
  title: string
  text: string
}

export class Note extends BaseEntity {
  readonly id: Uuid
  readonly title: string
  readonly text: string

  constructor(id: Uuid, title: string, text: string) {
    super()
    this.id = id
    this.title = title
    this.text = text
  }

  static fromAPI(data: NoteAPIResponse): Note {
    return new Note(Uuid.fromString(data.id), data.title, data.text)
  }

  public getTitle(): string {
    return this.title
  }
  public getText(): string {
    return this.text
  }
  public getId(): Uuid {
    return this.id
  }
}
