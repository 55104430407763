import { ValueObject } from './valueObject'
import { v4 as uuid, validate } from 'uuid'
import { InvalidUuid } from './invalidUuid'

export class Uuid extends ValueObject<string> {
  constructor(value: string) {
    super(value)
    this.ensureIsValidUuid(value)
  }

  static fromString(value: string): Uuid {
    return new Uuid(value)
  }

  static new(): Uuid {
    return new Uuid(uuid())
  }

  private ensureIsValidUuid(value: string): void {
    if (!validate(value)) {
      throw new InvalidUuid(value)
    }
  }
}
