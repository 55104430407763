<template>
  <v-text-field
    v-model="dateValue"
    :label="label"
    :value="dateValue"
    type="date"
    class="lca-input-datepicker"
    density="compact"
    :clearable="clearable"
    :disabled="disabled"
    :rules="rules"
  >
    <template v-slot:append>
      <i class="fa-solid fa-calendar" @click="openParty" />
    </template>
  </v-text-field>

  <v-dialog v-model="show" :fullscreen="device.width() < 400" width="300">
    <div class="dpk-container">
      <v-date-picker
        v-model="datePickerValue"
        color="primary"
        :max-width="device.isSmAndDown() ? '100%' : 'auto'"
        :min-width="device.isSmAndDown() ? '100%' : 'auto'"
        :hide-header="false"
        @update:model-value="transformPicker()"
        :max="maxInput"
        :min="minInput"
        :disabled="disabled"
      >
        <template #title></template>
        <template #actions>
          <v-btn @click="goClear()" v-if="props.clearable">
            {{ $t('core.clean') }}
          </v-btn>
          <v-btn @click="goNow()" color="primary" variant="text">
            {{ $t('core.today') }}
          </v-btn>
        </template>
      </v-date-picker>
    </div>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import DeviceClass from './../../application/services/device.class'
import { DateTime } from '../../application/valueObjects/dateTime'

const device = new DeviceClass()
const menu = ref(false)

defineOptions({
  name: 'InputDatePicker',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  label?: string
  format: 'date' | 'datetime'
  max?: string
  min?: string
  clearable?: boolean
  disabled?: boolean
  rules?: []
}
const props = defineProps<PropsType>()

const dateValue = defineModel()
const show = ref(false)
const datePickerValue = ref(new Date())

const transformPicker = () => {
  console.log('AAAA', datePickerValue.value)
  const _date = DateTime.fromDate(datePickerValue.value).format('YYYY-MM-DD')
  dateValue.value = _date
  show.value = false
  menu.value = false
}

const openParty = () => {
  datePickerValue.value = dateValue ? new Date(dateValue.value) : new Date()
  show.value = true
  menu.value = true
}

const goNow = () => {
  datePickerValue.value = new Date()
  transformPicker()
}

const goClear = () => {
  datePickerValue.value = null
  transformPicker()
}

const maxInput = computed(() => {
  if (props.max) {
    if (props.max === 'today') {
      return new Date().toISOString().split('T')[0]
    } else {
      return props.max
    }
  }
  return null
})

const minInput = computed(() => {
  if (props.min) {
    if (props.min === 'today') {
      return new Date().toISOString().split('T')[0]
    } else {
      return props.min
    }
  }
  return null
})
</script>

<style lang="scss">
.lca-input-datepicker {
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
</style>
