import { ValueObject } from './valueObject'

export class BlobDocument extends ValueObject<Blob> {
  constructor(value: Blob) {
    super(value)
  }

  static fromString(value: string, type: string): BlobDocument {
    const _value = new Blob([value], { type })
    return new BlobDocument(_value)
  }

  /*   static fromCanvas(canvas: HTMLCanvasElement, type: string): Promise<BlobDocument> {
    return new Promise((resolve, reject) => {
      canvas.toBlob( blob => {
        resolve(new BlobDocument(blob as Blob))
      })
    })
  } */

  public toBase64(): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(this.value)
    })
  }
}
