<template>
  <v-switch
    v-model="sw"
    :class="class"
    :color="color"
    :density="density"
    :disabled="disabled"
    :id="id"
    :label="label"
    :hide-details="hideDetails"
  >
    <slot></slot>
  </v-switch>
</template>

<script setup lang="ts">
defineOptions({
  name: 'Switch',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  color?: string
  density?: 'default' | 'comfortable' | 'compact'
  disabled?: boolean
  id?: string
  label?: string
  hideDetails?: boolean
  class?: string
}

const props = defineProps<PropsType>()
const sw = defineModel()
</script>
