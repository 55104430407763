<template>
  <lca-card class="p-r-20 p-l-20 m-b-20" no-action>
    <template #title>
      <div class="d-flex">
        <label class="title">{{ props.note.title }}</label>
        <i class="fa-regular fa-floppy-disk" v-if="loadingForm"></i>
        <lca-menu>
          <template #activator="{ props }">
            <lca-button
              :disabled="loadingForm || loadingDelete"
              v-bind="props"
              text
              class="m-l-10"
              icon="fa-solid fa-ellipsis-vertical"
            />
          </template>

          <lca-list>
            <lca-list-item class="item" @click="showUpdateModal">{{ $t('core.edit') }}</lca-list-item>
            <lca-divider />
            <lca-list-item class="item" @click="showDeleteConfirm">{{ $t('core.delete') }}</lca-list-item>
          </lca-list>
        </lca-menu>
      </div>
    </template>

    <lca-divider />

    <div class="p-5-10">
      <lca-textarea
        variant="underlined"
        @blur="updateNoteAction"
        :disabled="loadingForm || loadingDelete"
        v-model="noteFormData.text"
      >
      </lca-textarea>
    </div>
  </lca-card>

  <lca-modal
    v-model="show"
    :title="$t('module.notes.update')"
    :btn-success-title="$t('core.update')"
    :btn-disabled="loadingForm"
    @success="updateNote()"
  >
    <lca-form ref="noteForm">
      <lca-row>
        <lca-col cols="12">
          <lca-input
            v-model="noteFormData.title"
            :disabled="loadingForm"
            :rules="mandatoryFieldRule"
            :label="$t('module.notes.update')"
          ></lca-input>
        </lca-col>
      </lca-row>
    </lca-form>
  </lca-modal>
</template>

<script setup lang="ts">
import { inject, reactive, ref } from 'vue'
import { Note } from '@entity/backoffice/account/note.entity'
import { NotesService } from '@service/backoffice/account/notes.service'
import { toast, useI18n } from 'meya'
import { useUserStore } from '@/store/account/user.store'
const { t } = useI18n()
const show = defineModel()
const $notify = inject('$notify')
const props = defineProps({ note: Note })
const loadingForm = ref(false)
const loadingDelete = ref(false)
const userStore = useUserStore()
const noteForm = ref(null)
const noteFormData = reactive({
  title: props.note.title,
  text: props.note.text
})
const showDeleteConfirm = () => {
  $notify
    .confirm(t('module.notes.confirm'))
    .then(() => {
      loadingDelete.value = true
      NotesService.remove(userStore.getUserId(), props.note.id).then(() => {
        toast.success(t('module.notes.success.delete'))
        loadingDelete.value = false
      })
    })
    .catch(() => {})
}

const showUpdateModal = () => {
  show.value = true
}
const updateNote = async () => {
  const { valid } = await noteForm.value.validate()
  if (valid) {
    updateNoteAction()
  }
}
const updateNoteAction = () => {
  loadingForm.value = true
  NotesService.update(userStore.getUserId(), props.note.id, noteFormData.title, noteFormData.text).then(() => {
    show.value = false
    toast.success(t('module.notes.success.update'))
    loadingForm.value = false
  })
}
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.item {
  cursor: pointer;
}
.title {
  width: 100%;
}
</style>
