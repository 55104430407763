import { Uuid } from 'meya'
import { BaseEntity } from '@entity/common/base.entity'

export interface UserNotificationAPIResponse {
  id: string
  notification: string
  read: boolean
  extra: string | null
  created_at: string
}

export class UserNotification extends BaseEntity {
  readonly id: Uuid
  readonly notification: string
  readonly read: boolean
  readonly extra: string | null
  readonly createdAt: Date

  constructor(id: Uuid, notification: string, read: boolean, extra: string | null, createdAt: Date) {
    super()
    this.id = id
    this.notification = notification
    this.read = read
    this.extra = extra
    this.createdAt = createdAt
  }

  static fromAPI(data: UserNotificationAPIResponse): UserNotification {
    return new UserNotification(
      Uuid.fromString(data.id),
      data.notification,
      data.read,
      data.extra,
      new Date(data.created_at)
    )
  }

  public getId(): Uuid {
    return this.id
  }

  public getNotification(): string {
    return this.notification
  }

  public getRead(): boolean {
    return this.read
  }

  public getExtra(): string | null {
    return this.extra
  }

  public getCreatedAt(): Date {
    return this.createdAt
  }
}
