<template>
  <v-btn-toggle
    v-if="toggle"
    v-model="model"
    density="compact"
    :divided="divided"
    :class="class"
    :disabled="disabled"
    variant="outlined"
  >
    <slot></slot>
  </v-btn-toggle>

  <v-btn-group v-else density="compact" :divided="divided" :class="class" :disabled="disabled">
    <slot></slot>
  </v-btn-group>
</template>

<script setup lang="ts">
defineOptions({
  name: 'ButtonGroup',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  toggle?: boolean
  divided?: boolean
  class?: string
  disabled?: boolean
}

defineProps<PropsType>()

const model = defineModel()
</script>

<style lang="scss" scoped>
.v-btn-group {
  &.v-btn-group--divided {
    border-width: thin;
    border-style: solid;
    border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  }
}
</style>
