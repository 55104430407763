import { RouteRecordRaw } from 'vue-router'
import { PermissionList } from '@application/permisionList'

const paths: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'leads-main',
    component: () => import('@vBackoffice/leads/MainLeads.vue'),
    children: [
      {
        path: 'leads',
        name: 'leads-list',
        meta: { permission: PermissionList.LEADS_VIEW },
        component: () => import('@vBackoffice/leads/ListLeads.vue')
      },
      {
        path: 'lead/:id',
        name: 'lead-details',
        meta: { permission: PermissionList.LEADS_UPDATE },
        component: () => import('@vBackoffice/leads/DetailsLead.vue')
      }
    ]
  }
]

export default paths
