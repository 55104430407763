<template>
  <v-dialog v-model="show" :width="widthModal" v-bind:="$attrs" :persistent="true">
    <v-card class="lca-modal">
      <v-card-title>
        <span class="modal-title">{{ title }}</span>
      </v-card-title>

      <v-container fluid>
        <slot></slot>
      </v-container>

      <v-card-actions>
        <slot name="btn-delete" v-if="showDeleteBtn">
          <v-btn color="error" variant="text" @click="clickDelete" :disabled="btnDisabled">
            {{ btnDeleteText }}
          </v-btn>
        </slot>

        <v-spacer></v-spacer>
        <slot name="btn-cancel">
          <v-btn variant="text" @click="clickCancel" :disabled="btnDisabled">
            {{ btnCancelText }}
          </v-btn>
        </slot>
        <slot name="btn-success">
          <v-btn color="primary" variant="text" @click="clickSuccess()" :disabled="btnDisabled">
            {{ btnSuccessText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onUpdated, computed, getCurrentInstance } from 'vue'
import DeviceClass from './../../application/services/device.class'
import { useI18n } from './../../plugins/i18n'
const { t } = useI18n()

defineOptions({
  name: 'Modal',
  inheritAttrs: false,
  customOptions: {}
})

const emit = defineEmits(['success', 'cancel', 'delete'])

interface PropsType {
  title: string
  btnDisabled?: boolean
  btnSuccessTitle?: string
  btnCancelTitle?: string
  btnDeleteTitle?: string
  showDeleteBtn?: boolean
  middleFullscreen?: boolean
}
const props = defineProps<PropsType>()

const show = defineModel<boolean | undefined>()
const device = new DeviceClass()
const widthModal = ref('auto')

const btnSuccessText = computed(() => props.btnSuccessTitle ?? t('core.save'))
const btnCancelText = computed(() => props.btnCancelTitle ?? t('core.cancel'))
const btnDeleteText = computed(() => props.btnDeleteTitle ?? t('core.delete'))

const getWidthModal = () => {
  if (props.middleFullscreen) {
    widthModal.value = '95%'
    return
  }

  if (device.isSmAndDown()) {
    widthModal.value = '100%'
  } else if (device.isMdAndDown()) {
    widthModal.value = '600'
  } else if (device.isLgAndDown()) {
    widthModal.value = '800'
  } else if (device.isLgAndUp()) {
    widthModal.value = '1024'
  } else {
    widthModal.value = 'auto'
  }
}

onUpdated(() => {
  if (!props.widthModal) {
    getWidthModal()
  }
})

const instance = getCurrentInstance()

const clickSuccess = () => {
  if (instance?.vnode?.props?.onSuccess) {
    emit('success')
  } else {
    show.value = false
  }
}

const clickCancel = () => {
  if (instance?.vnode?.props?.onCancel) {
    emit('cancel')
  } else {
    show.value = false
  }
}

//const hasDeleteAction = computed((): boolean => (instance?.vnode?.props?.onDelete && instance?.vnode?.props?.onDelete !== undefined && instance?.vnode?.props?.onDelete !== null))
const clickDelete = () => {
  emit('delete')
}
</script>

<style lang="scss" scoped>
.lca-modal {
  padding: 10px 20px;

  .modal-title {
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
