import axios, { type CancelTokenSource, type CancelToken, type AxiosRequestConfig } from 'axios'
import SecurityClass from './security.class'
import { Utils } from '../utils'

interface ConfigOptions {
  cancelToken?: CancelTokenSource
  public?: boolean
  responseType?: 'blob' | undefined
  hideError?: boolean
}

export type { CancelTokenSource, ConfigOptions }

export class ApiClass {
  protected host: string
  protected token: string | null = null
  protected security: SecurityClass = new SecurityClass()

  constructor(host: string) {
    this.host = host
  }

  // Helpers
  protected _transformUrl(url: string): string {
    if (url[0] == '/') {
      url = url.substring(1)
    }
    return this.host + '/api/' + url
  }

  protected _parseConfig(options?: ConfigOptions) {
    let config: AxiosRequestConfig = {
      headers: this._getHeaders(options)
    }

    if (options && options.cancelToken !== undefined) {
      config.cancelToken = options.cancelToken.token
    }

    if (options?.responseType) {
      config.responseType = options.responseType
    }

    return config
  }

  protected _getHeaders(options?: ConfigOptions) {
    let headers: { Authorization?: string; 'Accept-Language'?: string } = {}

    if (!(options && options?.public === true)) {
      headers.Authorization = `Bearer ${this.security.getSession()}`
    }

    headers['Accept-Language'] = Utils.getLanguage('en', [])

    return headers
  }

  /**
   * Genera una peticion HTTP GET
   *
   * @param url
   * @returns Promise
   */
  public get(url: string, options?: ConfigOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(this._transformUrl(url), this._parseConfig(options))
        .then(response => {
          const headers = response.headers
          let dataReturn = response.data ?? response

          if (headers['content-type'] !== 'application/json' && options?.responseType === 'blob') {
            dataReturn = new Blob([response.data ?? response], { type: headers['content-type']?.toString() ?? '' })
          }
          resolve(dataReturn)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Genera una peticion HTTP POST
   *
   * @param url
   * @param data
   * @returns
   */
  public post(url: string, data: object, options?: ConfigOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(this._transformUrl(url), data, this._parseConfig(options))
        .then(response => resolve(response.data ?? response))
        .catch(error => reject(error))
    })
  }

  /**
   * Genera una peticion HTTP PUT
   *
   * @param url
   * @param data
   * @returns
   */
  public put(url: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(this._transformUrl(url), data, { headers: this._getHeaders() })
        .then(response => resolve(response.data ?? response))
        .catch(error => reject(error))
    })
  }

  /**
   * Genera una peticion HTTP DELETE
   *
   * @param url
   * @param data
   * @returns
   */
  public delete(url: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete(this._transformUrl(url), { headers: this._getHeaders(), data })
        .then(response => resolve(response.data ?? response))
        .catch(error => reject(error))
    })
  }

  public generateCancelToken(): CancelTokenSource {
    return axios.CancelToken.source()
  }

  // Gestion de Token
  public setToken(token: string | null): void {
    this.token = token
  }

  public getToken(): string | null {
    return this.token
  }
}
