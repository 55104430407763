<template>
  <div class="lca-image" :style="{ width: props.width, height: props.height }">
    <v-menu
      open-on-hover
      location="end bottom"
      :width="props.width"
      open-delay="0"
      offset="-48"
      transition="fade-transition"
    >
      <template v-slot:activator="{ props: propsActivator }">
        <v-btn :width="props.width" :height="props.height" v-bind="propsActivator">
          <div class="" v-if="props.loading">
            <i class="fa-solid fa-spinner fa-spin fa-3x"></i>
          </div>
          <div v-else>
            <v-img cover :src="props.image" :width="props.width" :height="props.height" />
          </div>
        </v-btn>
      </template>
      <v-btn color="primary" icon="fa-solid fa-pencil" size="default" @click="editFile()" :disabled="props.loading" />
    </v-menu>

    <input ref="lcaFileInputImage" type="file" style="display: none" @change="changeFile" />

    <v-dialog v-model="cropperModal" width="400">
      <v-card class="d-cropper">
        <div class="lca-image-cropper-loading" v-if="imgLoading">
          <i class="fa-solid fa-spinner fa-spin fa-3x"></i>
        </div>

        <cropper
          v-else
          ref="lcaImageCropper"
          class="lca-image-cropper"
          :src="newImg"
          :stencil-component="CircleStencil"
          backgroundClass="cropper-bkg"
        />

        <v-card-actions>
          <v-btn @click="onCancel">
            {{ $t('core.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="onSave">
            {{ $t('core.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

defineOptions({
  name: 'Image',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  image: string | null
  width: string
  height: string
  loading?: boolean
}
const props = defineProps<PropsType>()
const emits = defineEmits(['change'])

const lcaFileInputImage = ref(null)
const cropperModal = ref(false)
const lcaImageCropper = ref(null)
const newImg = ref(null)
const imgLoading = ref(false)

const editFile = () => {
  lcaFileInputImage.value.click()
}

const changeFile = () => {
  const file = lcaFileInputImage.value.files[0]
  if (file) {
    imgLoading.value = true
    cropperModal.value = true
    const reader = new FileReader()
    reader.onload = e => {
      newImg.value = e.target.result
      imgLoading.value = false
    }
    reader.readAsDataURL(file)
  }
}

const onCancel = () => {
  cropperModal.value = false
  newImg.value = null
}

const onSave = () => {
  const { canvas } = lcaImageCropper.value.getResult()
  emits('change', canvas)
  cropperModal.value = false
  newImg.value = null
}
</script>
<style lang="scss">
.v-card {
  &.d-cropper {
    padding: 20px;
  }
}

.lca-image-cropper-loading {
  height: 320px;
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.lca-image-cropper {
  height: 320px;
  width: 320px;
  margin: 0 auto;
}

.cropper-bkg {
  background-color: #ffffff;
}
</style>
