import { RouteRecordRaw } from 'vue-router'

const paths: Array<RouteRecordRaw> = [
  {
    path: 'promotions',
    name: 'employee-promotion-main',
    component: () => import('@vEmployee/promotion/MainPromotion.vue'),
    children: [
      {
        path: '',
        name: 'employee-promotions-list',
        component: () => import('@vEmployee/promotion/ListPromotion.vue')
      },
      {
        path: ':id',
        name: 'employee-promotions-detail',
        component: () => import('@vEmployee/promotion/DetailsPromotion.vue')
      },
      {
        path: ':promotionId/:scheduleId/:weekId/:dayId/block/:blockId',
        name: 'employee-block-detail',
        component: () => import('@vEmployee/promotion/DetailsBlock.vue')
      },
      {
        path: ':promotionId/:scheduleId/:weekId/:dayId/block/:blockId/exam',
        name: 'employee-block-detail-exam',
        component: () => import('@vEmployee/promotion/DetailsBlockExam.vue')
      }
    ]
  }
]

export default paths
