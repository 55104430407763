import PromotionRoutes from './modules/promotion'
import { ScopeEnum } from '@/application/entities/common/login/scope.enum'

export default [
  {
    path: 'employee',
    name: 'employee-base',
    meta: { scope: ScopeEnum.EMPLOYEE },
    component: () => import('@vEmployee/EmployeeView.vue'),
    children: [
      {
        path: 'home',
        name: 'employee-home',
        component: () => import('@vEmployee/dashboard/DashboardView.vue')
      },
      ...PromotionRoutes
    ]
  }
]
