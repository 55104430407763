export type Primitive = string | number | boolean | symbol | dayjs.Dayjs | null | undefined | Blob
import dayjs from 'dayjs'

export abstract class ValueObject<T extends Primitive> {
  readonly value: T

  constructor(value: T) {
    this.value = value
  }

  getValue(): T {
    return this.value
  }
}
