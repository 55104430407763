<template>
  <div id="pdf-viewer-container" ref="pdfViewerContainer">
    <div id="viewer" class="pdfViewer" ref="pdfViewer"></div>
  </div>
  <!-- <canvas id="theCanvas"></canvas> -->
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'

import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import { PDFViewer, EventBus, PDFFindController, PDFLinkService } from 'pdfjs-dist/web/pdf_viewer'
import 'pdfjs-dist/web/pdf_viewer.css'

/**
 * Desde el directorio de public, generar el siguiente enlace simbolico
 *
 * ln -s ../node_modules/meya/node_modules/pdfjs-dist/build/pdf.worker.min.mjs .
 *
 * No se puede coger directamente desde node_modules aqui porque se llama por el worker y tiene que ser una url publica
 */
//pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.js"
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs'

defineOptions({
  name: 'PDFViewer',
  inheritAttrs: false,
  customOptions: {}
})
interface PropsType {
  content: Blob
  zoom: number
}
const props = defineProps<PropsType>()
const emit = defineEmits(['valuesPage', 'valuesSearch'])

const contentPDF = computed(() => {
  return URL.createObjectURL(props.content)
})

const eventBus = new EventBus()

const pdfLinkService = new PDFLinkService({
  eventBus
})

const pdfFindController = new PDFFindController({
  eventBus,
  linkService: pdfLinkService
})

const pdfViewerContainer = ref(null)

const render = async () => {
  const viewer = new PDFViewer({
    container: pdfViewerContainer.value,
    eventBus,
    linkService: pdfLinkService,
    findController: pdfFindController
  })

  pdfLinkService.setViewer(viewer)

  eventBus.on(
    'pagesinit',
    function (e) {
      emit('valuesPage', { current: 1, total: e.source.pagesCount })
      viewer.currentScaleValue = props.zoom / 100 + 0.5
    }.bind(this)
  )

  eventBus.on(
    'updatefindmatchescount',
    function (e) {
      emit('valuesSearch', e.matchesCount)
    }.bind(this)
  )

  eventBus.on(
    'scalechanging',
    function () {
      viewer.currentScaleValue = props.zoom / 100 + 0.5
    }.bind(this)
  )

  eventBus.on(
    'pagechanging',
    function (e) {
      emit('valuesPage', { current: e.pageNumber, total: e.source.pagesCount })
    }.bind(this)
  )

  eventBus.on(
    'updatetextlayermatches',
    function () {
      emit('valuesSearch', this.requestMatchesCount())
    }.bind(this)
  )

  console.log('contentPDF', contentPDF)

  const loadingTask = pdfjsLib.getDocument({
    url: contentPDF.value,
    verbosity: 0 // Ocultar errores en consola. | Warning: TT: undefined function: 32 => Error de fuentes
    /* cMapUrl: "pdfjs-dist/cmaps/",
    cMapPacked: true,
    enableXfa: true  */
  })

  const pdfDocument = await loadingTask.promise
  viewer.setDocument(pdfDocument)
  pdfLinkService.setDocument(pdfDocument, null)

  // Ocultar las capas de impresion
  let config = await pdfDocument.getOptionalContentConfig()
  let groups = config.getGroups()
  if (groups && Object.keys(groups).length > 0) {
    Object.keys(groups).forEach(key => {
      if (groups[key].name === 'print') {
        config.setVisibility(key, false)
      }
    })
    viewer.optionalContentConfigPromise = Promise.resolve(config)
  }
}

onMounted(() => {
  render()
})

watch(
  () => props.zoom,
  () => {
    eventBus.dispatch('scalechanging', {
      source: this,
      scale: 1,
      presetValue: 1
    })
  }
)
</script>

<style lang="scss">
#pdf-viewer-container {
  position: absolute;
  top: 90px;

  overflow: scroll;
  height: calc(100% - 90px);
  width: calc(100% - 10px);
}
</style>
