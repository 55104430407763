<template>
  <v-dialog
    v-model="model"
    :height="height"
    :width="width"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-height="minHeight"
    :min-width="minWidth"
    :opacity="opacity"
    :activator="activator"
    :persistent="persistent"
  >
    <slot></slot>
  </v-dialog>
</template>

<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue'

defineOptions({
  name: 'Dialog',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  height?: string | number
  width?: string | number
  maxHeight?: string | number
  maxWidth?: string | number
  minHeight?: string | number
  minWidth?: string | number
  opacity?: string | number
  activator?: Element | 'parent' | (string & {}) | ComponentPublicInstance | undefined
  persistent?: boolean
}

const props = defineProps<PropsType>()

const model = defineModel()
</script>
