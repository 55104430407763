import { RouteRecordRaw } from 'vue-router'
import { PermissionList } from '@application/permisionList'

const paths: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'module-main',
    component: () => import('@vBackoffice/knowledges/MainModules.vue'),
    children: [
      {
        path: 'knowledges',
        name: 'knowledges',
        meta: { permission: PermissionList.KNOWLEDGE_VIEW },
        component: () => import('@vBackoffice/knowledges/module/ListModule.vue')
      },
      {
        path: 'module/:id',
        name: 'module-details',
        meta: { permission: PermissionList.KNOWLEDGE_VIEW },
        component: () => import('@vBackoffice/knowledges/module/DetailsModule.vue')
      },
      {
        path: 'module/:id/practice/:practiceId',
        name: 'module-practice-details',
        meta: { permission: PermissionList.KNOWLEDGE_VIEW },
        component: () => import('@vBackoffice/knowledges/module/parts/DetailsModulePractice.vue')
      },
      {
        path: 'module/:id/practice/:practiceId/review/:reviewId',
        name: 'module-practice-review-details',
        meta: { permission: PermissionList.KNOWLEDGE_VIEW },
        component: () => import('@vBackoffice/knowledges/module/parts/DetailsModulePracticeReview.vue'),
        props: (route) => ({ reviewId: route.params.reviewId })
      }
    ]
  }
]

export default paths
