import { RouteRecordRaw } from 'vue-router'
import { PermissionList } from '@application/permisionList'

const paths: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'student-main',
    component: () => import('@vBackoffice/student/MainStudent.vue'),
    children: [
      {
        path: 'students',
        name: 'student-list',
        meta: { permission: PermissionList.STUDENTS_VIEW },
        component: () => import('@vBackoffice/student/ListStudent.vue')
      },
      {
        path: 'students/:id',
        name: 'student-detail',
        meta: { permission: PermissionList.STUDENTS_VIEW },
        component: () => import('@vBackoffice/student/DetailsStudent.vue')
      },
      {
        path: 'students/:id/paymentOrder/:paymentOrderId',
        name: 'payment-order-detail',
        meta: { permission: PermissionList.STUDENT_PAYMENTS_VIEW },
        component: () => import('@vBackoffice/student/DetailsPaymentOrder.vue')
      }
    ]
  }
]

export default paths
