import { type App } from 'vue'

import { mountComponent } from './common'
import Toast from './Toast.vue'
import ModalConfirm from './ModalConfirm.vue'

import type { NotifyOptions, NotifyPlugin } from './../../application/interfaces/plugins/notify.interfaces'

type ConfirmResult = boolean | string | object | any

export function createNotify( app: App, options?: NotifyOptions ): NotifyPlugin {

  const defaultToastOptions = {
    handleCancel: false,
    timer: true,
    timeout: 2000,
    transition: 'v-scroll-x-transition',
    location: 'top right',
    closable: true
  }

  const success = ( content: string | Object, opts: object = {} ): void => {
    mountComponent({
      component: Toast,
      app,
      status: 'success',
      content,
      options: { ...defaultToastOptions, ...opts }
    })
    .catch( () => { })
  }

  const error = ( content: string | Object, opts: object = {} ): void => {
    mountComponent({
      component: Toast,
      app,
      status: 'error',
      content,
      options: { ...defaultToastOptions, ...opts }
    })
    .catch( () => { })
  }

  const info = ( content: string | Object, opts: object = {} ): void => {
    mountComponent({
      component: Toast,
      app,
      status: 'info',
      content,
      options: { ...defaultToastOptions, ...opts }
    })
    .catch( () => { })
  }

  const warning = ( content: string | Object, opts: object = {} ): void => {
    mountComponent({
      component: Toast,
      app,
      status: 'warning',
      content,
      options: { ...defaultToastOptions, ...opts }
    })
    .catch( () => { })
  }

  const defaultConfimOptions = {
    handleCancel: true,
    width: '500',
    successButtonText: options?.confirm?.successButtonText ?? 'Continue',
    cancelButtonText: options?.confirm?.cancelButtonText ?? 'Cancel',
  }

  const confirm = ( content: string | Object, opts: object = {} ): Promise<ConfirmResult> => {
    return mountComponent({
      component: ModalConfirm,
      app,
      status: 'confirm',
      content,
      options: { ...defaultConfimOptions, ...opts }
    })
  }

  // Por ahora no usamos estos Toast porque no los permite apilar unos con otros y hay que hacer una gestion mayor de todo ello
  return {
    confirm
  }

}

