export class Utils {
  static getLanguage(defaultLanguage: string, availableLanguages: string[]): string {
    let language = localStorage.getItem('meya-language')
    if (language && (availableLanguages.length == 0 || availableLanguages.includes(language))) {
      return language
    }

    let userLang = navigator.language
    if (!userLang) {
      userLang = (navigator as any).userLanguage
    }

    if (userLang) {
      userLang = userLang.split('-')[0]
      if (availableLanguages.length == 0 || availableLanguages.includes(userLang)) {
        return userLang
      }
    }

    return defaultLanguage
  }
}
