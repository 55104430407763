import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Uuid } from 'meya'

import { MeAPIResponse } from '@entity/common/login/me.interface'
import { ScopeEnum } from '@entity/common/login/scope.enum'
import { Note, NoteAPIResponse } from '@entity/backoffice/account/note.entity'
import ScopeManager from '@application/scope.manager'

export const useUserStore = defineStore('user', () => {
  const id = ref<Uuid>() // ID AUTH
  const userId = ref<Uuid>()
  const name = ref<string>('')
  const surname = ref<string>('')
  const email = ref<string>('')
  const roleId = ref<Uuid>()
  const currentScope = ref<ScopeEnum>()
  const notes = ref<Note[]>([])

  let rawMe: MeAPIResponse
  let scopes: ScopeEnum[] = []
  let isAdmin: boolean = false
  let permissions: string[] = []

  // GETTERS
  const getId = (): Uuid | undefined => id.value
  const getUserId = (): Uuid | undefined => userId.value
  const getName = (): string => name.value
  const getSurname = (): string => surname.value
  const getEmail = (): string => email.value
  const getRoleId = (): Uuid | undefined => roleId.value
  const getCurrentScope = (): ScopeEnum | undefined => currentScope.value
  const getScopes = (): ScopeEnum[] => scopes
  const getNotes = (): Note[] => (notes.value ?? []) as Note[]

  // Cargar los datos del usuario a partir de la respuesta del /me
  const load = (data: MeAPIResponse): void => {
    rawMe = data

    // Recuperar el scope actual del usuario
    _loadScopesAvailables()

    // Cargar el scope actual del usuario
    _loadCurrentScope()

    id.value = Uuid.fromString(data.id)
    let language = null

    if (data.user) {
      language = data.user.language
    } else if (data.employee) {
      language = data.employee.language
    } else if (data.student) {
      language = data.student.language
    }

    if (language == null) {
      localStorage.removeItem('meya-language')
    } else {
      localStorage.setItem('meya-language', language)
    }
  }

  // Cargar los scopes disponibles para el usuario en funcion de la respuesta del /me
  const _loadScopesAvailables = (): void => {
    scopes = []

    if (rawMe.user && rawMe.user != null) {
      scopes.push(ScopeEnum.BACKOFFICE)
    }

    if (rawMe.employee && rawMe.employee != null) {
      scopes.push(ScopeEnum.EMPLOYEE)
    }

    if (rawMe.student && rawMe.student != null) {
      scopes.push(ScopeEnum.STUDENT)
    }
  }

  const _loadCurrentScope = (): void => {
    const scope = ScopeManager.getScope()
    if (scope != null) {
      if (!scopes.includes(scope as ScopeEnum)) {
        ScopeManager.removeScope()
        throw new Error('Scope not allowed')
      }
      currentScope.value = scope as ScopeEnum
      _loadDataFromScope()
    }
  }

  const _loadDataFromScope = (): void => {
    if (currentScope.value == ScopeEnum.BACKOFFICE) {
      _loadDataFromBackofficeScope()
    }
    if (currentScope.value == ScopeEnum.EMPLOYEE) {
      // Cargar datos del empleado
      _loadDataFromEmployeeScope()
    }
    if (currentScope.value == ScopeEnum.STUDENT) {
      // Cargar datos del estudiante
    }
  }

  // Cargar datos del usuario backoffice
  const _loadDataFromBackofficeScope = (): void => {
    const data = rawMe.user
    if (data) {
      userId.value = Uuid.fromString(data.id)
      name.value = data.name
      surname.value = data.surname
      email.value = data.email
      roleId.value = Uuid.fromString(data.role_id)

      isAdmin = data.is_admin ?? false
      permissions = []
      if (data.permissions) {
        permissions = data.permissions.map(p => p.name)
      }

      if (data.notes) {
        notes.value = data.notes.map((note: NoteAPIResponse) => Note.fromAPI(note))
      }
    }
  }

  // Cargar datos del usuario empleado
  const _loadDataFromEmployeeScope = (): void => {
    const data = rawMe.employee
    if (data) {
      userId.value = Uuid.fromString(data.id)
      name.value = data.name
      surname.value = data.surname
      email.value = data.email

      isAdmin = false
      permissions = []
    }
  }

  // Cambiar el scope actual del usuario
  const changeScope = (scope: ScopeEnum): void => {
    if (!scopes.includes(scope)) {
      console.error('Scope not allowed')
      throw new Error('Scope not allowed')
    }

    currentScope.value = scope
    ScopeManager.setScope(scope)
    _loadDataFromScope()
  }

  // Comprobar si el usuario tiene un permiso
  const hasPermission = (permission: string): boolean => {
    return isAdmin || permissions.filter(p => p === permission).length > 0
  }

  // Cargar las notas del usuario
  const loadNotes = (_notes: Note[]): void => {
    notes.value = _notes
  }

  return {
    getId,
    getUserId,
    getName,
    getSurname,
    getEmail,
    getRoleId,
    getCurrentScope,
    getScopes,
    getNotes,

    load,
    changeScope,

    hasPermission,
    loadNotes
  }
})
