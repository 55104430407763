import { BaseService } from '@service/common/base.services'
import { useUserStore } from '@stores/account/user.store'

export class MeService extends BaseService {
  static getCurrentUser(): Promise<boolean> {
    return this.get('/v1/common/account/me').then(data => {
      const userStore = useUserStore()
      userStore.load(data)
      return true
    })
  }
}
