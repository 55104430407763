export default {
  core: {
    welcome: 'Welcome {name}!!',
    yes: 'Yes',
    no: 'No',
    notify: 'Notify',
    show: 'Show',
    save: 'Save',
    continue: 'Continue',
    add: 'Add',
    delete: 'Remove',
    update: 'Update',
    updateFile: 'Update file',
    edit: 'Edit',
    image: 'Image',
    file: 'File',
    extension: 'Extension',
    size: 'Size',
    email: 'Email',
    name: 'Name',
    surname: 'Surname',
    rol: 'Role',
    roles: 'Roles',
    changeNewRole: 'Change to new role',
    user: 'User',
    changePassword: 'Change password',
    changeLanguage: 'Change language',
    password: 'Password',
    lastPassword: 'Last password',
    newPassword: 'New password',
    rePassword: 'Repeat the password',
    watch: 'Show',
    active: 'Active',
    inactive: 'Inactive',
    back: 'Back',
    notPermissionPage: 'You do not have permission to access this page',
    notFoundPage: 'The page you are looking for does not exist',
    description: 'Description',
    date: 'Date',
    integrations: 'Integrations',
    key: 'Key',
    expirationDate: 'Expiration date',
    language: 'Language',
    noVersionsAvailable: 'No versions available',
    hours: 'Hours',
    review: 'Review',
    leadReportTypes: 'Lead Report Types',
    leads: 'Leads',
    status: 'Status',
    report: 'Report',
    type: 'Type',
    toast: {
      success: {
        create: 'Successfully created',
        notified: 'Notified',
        save: 'Saved successfully',
        add: 'Successfully added',
        update: 'Successfully updated',
        delete: 'Successfully removed'
      },
      error: 'Operation error',
      errorDate: 'The date cannot be greater than the current date',
      errorNotData: 'You have not selected any data'
    },
    confirmation: {
      delete: 'Are you sure you want to delete it?',
      create: 'Are you sure you want to create it?'
    },
    validations: {
      userMandatory: 'User is required',
      passwordMandatory: 'Password is required',
      codeMandatory: 'Instructor code is required',
      nameMandatory: 'Name is required',
      emailMandatory: 'Email is required',
      roleMandatory: 'Role is required',
      fieldMandatory: 'This field is required',
      urlInvalid: 'Invalid URL',
      rePass: 'Passwords do not match',
      minPass: 'The password must have at least 8 characters',
      customPassword:
        'The password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    },
    calendar: {
      days: {
        weekOfDays: 'Week of days',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
      }
    }
  },
  module: {
    login: {
      login: 'Go to App',
      recoveryPassword: 'Recovery Password'
    },
    dashboard: {
      title: 'Home',
      modules: 'knowledges'
    },
    knowledge: {
      title: 'Module',
      lessons: 'Number of lessons',
      lastUpdate: 'Last Update',
      newModule: 'Add Module',
      modalModule: {
        title: 'Create Module',
        name: 'Module Name',
        lessons: 'Number of lessons'
      },
      submodule: {
        title: 'Submodules',
        numberOfLessons: 'Number of Lessons',
        editModule: 'Edit Module',
        newSubmodule: 'New Submodule',
        editSubmodule: 'Edit Submodule'
      },
      subjectMatter: {
        title: 'Subjects',
        newSubjectMatter: 'New Subject',
        editSubjectMatter: 'Edit Subject'
      },
      manuals: {
        title: 'Manuals',
        newManual: 'New Subject',
        editManual: 'Edit Subject',
        showManual: 'Show Manual'
      },
      manualVersions: {
        title: 'Manual Versions',
        newManualVersion: 'New Manual Version',
        editManualVersion: 'Edit Manual Version',
        showManualVersion: 'Show Manual Version',
        edition: 'Edition',
        revision: 'Revision',
        editionDate: 'Edition Date'
      },
      practice: {
        title: 'Practices',
        new: 'New Practice',
        create: 'Create Practice',
        edit: 'Edit Practice',
        info: 'Practice Information',
        list: 'List of practices',
        dueDate: 'Due date',
        examn: 'Exam',
        examDocument: 'Exam document',
        qualification: 'Qualification',
        details: {
          name: 'Practice name',
          description: 'Description',
          sessions: 'Sessions',
          groups: 'Groups',
          isDefault: 'Default practice',
          viewDoc: 'View document',
          document: 'Document'
        },
        review: {
          title: 'Review practice',
          new: 'New review',
          create: 'Create review',
          edit: 'Edit review',
          info: 'Review information',
          list: 'List of reviews',
          sessionList: 'Session list',
          sessionEdit: 'Edit session',
          sessionCreate: 'Create session',
          warning: 'The number of sessions has not been reached',
          groupEdit: 'Edit group',
          groupCreate: 'Create group'
        }
      },
      newLesson: 'New Lesson',
      newLessonTitle: 'Create Lesson',
      editLessonTitle: 'Edit Lesson',
      listLesson: 'List of lessons'
    },
    event: {
      title: 'App Events'
    },
    rolesAdmin: {
      title: 'Role management',
      new: 'New role',
      create: 'Create role',
      edit: 'Edit role',
      name: 'Role name'
    },
    employee: {
      title: 'Instructors',
      nameEmployee: 'Instructor name',
      new: 'New instructor',
      create: 'Create instructor',
      edit: 'Edit instructor',
      info: 'Instructor info',
      details: {
        code: 'Instructor code',
        dni: 'DNI / NIE',
        photo: 'Photo of DNI / NIE',
        phone: 'Phones of contact',
        address: 'Addresses of contact',
        birthdate: 'Birthdate',
        tshirtSize: 'T-Shirt size',
        jacketSize: 'Jacket size',
        pantsSize: 'Pants size',
        cv: 'CV',
        comments: 'Comments',
        prefix: 'Phone prefix',
        number: 'Phone number',
        isManin: 'Primary contact',
        idCardNumberFileUpdated: 'File updated'
      },
      addresses: {
        address: 'Address',
        street: 'Street',
        number: 'Number',
        floor: 'Floor',
        door: 'Door',
        city: 'City',
        state: 'State',
        zipCode: 'Zip code',
        country: 'Country'
      },
      workExperience: {
        title: 'Work Experience',
        new: 'New Work Experience',
        edit: 'Edit',
        company: 'Company',
        position: 'Position',
        startDate: 'Start Date',
        endDate: 'End Date',
        isCurrentJob: 'Currently Working Here',
        description: 'Description',
        yes: 'Yes',
        no: 'No',
        endDataError: 'End date cannot be earlier than start date'
      },
      postEmployeeTraining: {
        title: 'Instructor Training',
        name: 'Training name',
        startDate: 'Start date',
        endDate: 'End date',
        isCompleted: 'Is completed',
        organization: 'Organization',
        quality: 'Quality',
        description: 'Description',
        document: 'Document',
        replanning: 'Replanning',
        newStartDate: 'New start date',
        newEndDate: 'New end date',
        reasons: 'Reasons'
      },
      replanning: {
        errorDate: 'The new start date cannot be greater than the new end date'
      },
      previousEmployeeTraining: {
        title: 'Previous instructor Training',
        name: 'Name',
        startDate: 'Start date',
        endDate: 'End date',
        organization: 'Organization',
        quality: 'Quality',
        description: 'Description',
        document: 'Document',
        reasons: ''
      },
      employeeModule: {
        examiner: 'Examiner',
        instructor: 'Instructor',
        evaluator: 'Evaluator'
      }
    },
    student: {
      title: 'Students',
      new: 'New student',
      create: 'Create student',
      edit: 'Edit student',
      info: 'Student info',
      listStudent: 'List of students',
      addStudent: 'Add student',
      deleteStudent: 'Delete student',
      generateAgreement: 'Generate agreement',
      generateF06: 'Generate F06 document',
      showF06: 'Show F06 document',
      document: {
        title: 'Documents',
        new: 'New document',
        edit: 'Edit document',
        list: 'List of documents',
        name: 'Document name',
        updated: 'Updated document'
      },
      details: {
        dni: 'DNI / NIE',
        photo: 'Photo of DNI / NIE',
        phone: 'Phones of contact',
        altPhone: 'Alternative phones',
        altEmail: 'Alternative emails',
        address: 'Addresses of contact',
        birthdate: 'Birthdate',
        cancellation_date: 'Cancellation date',
        tshirtSize: 'T-Shirt size',
        jacketSize: 'Jacket size',
        pantsSize: 'Pants size',
        poloSize: 'Polo size',
        idCardNumberFileUpdated: 'File updated'
      },
      addresses: {
        address: 'Address',
        street: 'Street',
        number: 'Number',
        city: 'City',
        state: 'State',
        zipCode: 'Zip code',
        country: 'Country',
        extra: 'portal, stairs, etc...'
      },
      paymentOrder: {
        title: 'Payment Orders',
        new: 'New Payment Order',
        price: 'Price',
        edit: 'Edit Payment Order',
        finalPrice: 'Final Price',
        discount: 'Discount',
        pendingAmount: 'Pending Amount',
        paymentPayed: 'Payment Payed',
        description: 'Description',
        priceMax: 'Price max',
        priceMin: 'Price min',
        discountMax: 'Discount max',
        discountMin: 'Discount min',
        pendingAmountMax: 'Pending Amount max',
        pendingAmountMin: 'Pending Amount min',
        paymentPayedMax: 'Payment Payed max',
        paymentPayedMin: 'Payment Payed min'
      },
      payment: {
        title: 'Payments',
        add: 'Add Payment',
        edit: 'Edit Payment',
        state: 'State of payment',
        approve: 'Approve payment',
        cancel: 'Cancel payment',
        amount: 'Amount',
        invoiceNumber: 'Invoice Number',
        invoiceDate: 'Invoice Date',
        paymentDate: 'Payment Date',
        pending: 'Pending',
        approved: 'Approved',
        canceled: 'Canceled',
        paid: 'Paid'
      }
    },
    user: {
      title: 'Users',
      edit: 'Edit user',
      info: 'User info',
      lastSession: 'Last session'
    },
    promotions: {
      title: 'Promotions',
      new: 'New promotion',
      create: 'Create promotion',
      edit: 'Edit promotion',
      info: 'Promotion description',
      promotion: 'Promotion',
      details: {
        name: 'Promotion name',
        startDate: 'Start date',
        endDate: 'End date',
        comments: 'Comments',
        updateComments: 'Update comments',
        status: 'Status'
      },
      studentList: 'Student list',
      notStudents: 'No students have been in the promotion',
      attendance: {
        list: 'Attendance list',
        options: {
          present: 'Present',
          telematic: 'Online',
          absent: 'Absent'
        }
      },
      notSchedule: 'No hay cronograma asociado/activo',
      session: {
        title: 'Sessions',
        name: 'Session name',
        nStudents: 'Number of students',
        confirm: 'Are you sure you want to delete the session?'
      }
    },
    notes: {
      new: 'New note',
      create: 'Create note',
      update: 'Update title',
      success: {
        create: 'Note created',
        update: 'Note updated',
        delete: 'Note deleted'
      },
      confirm: 'Are you sure you want to delete the note?'
    },
    schedule: {
      title: 'Schedule',
      new: 'New schedule',
      create: 'Create schedule',
      configTitle: 'New schedule configuration',
      notHolidayDays: 'No days created',
      blocks: {
        new: 'New block',
        createTitle: 'Default blocks for days',
        notCreated: 'No block has been created',
        startTime: 'Start time',
        endTime: 'End time',
        types: {
          title: 'Block type',
          classroom: 'Class',
          recess: 'Break'
        }
      },
      validations: {
        notSameStartTime: 'The start time cannot be the same as the end time'
      },
      state: {
        state: 'State of the schedule',
        draft: 'Draft',
        approved: 'Approved',
        approve: 'Approve'
      }
    },
    notifications: {
      showNotRead: 'Show only not read'
    },
    leads: {
      title: 'Leads',
      new: 'New lead',
      edit: 'Edit lead',
      info: 'Lead info',
      editStatus: 'Edit status',
      list: 'Listado de leads',
      status: {
        pending: 'Pending',
        inProgress: 'In progress',
        approved: 'Approved',
        rejected: 'Rejected',
        1: 'Pending',
        2: 'In progress',
        3: 'Approved',
        4: 'Rejected'
      },
      details: {
        phone: 'Phone',
        source: 'Source',
        interested: 'Interested',
        comments: 'Comments',
        document: 'Document',
        createStudent: 'Create student',
        createStudentConfirmation:
          'A new student will be created with the current data, are you sure you want to continue?'
      },
      reports: {
        title: 'Reports',
        new: 'New report',
        edit: 'Edit report'
      }
    },
    setting: {
      apiKeysAdmin: {
        title: 'API Keys Management',
        new: 'New API Key',
        create: 'Create API Key',
        edit: 'Edit API Key',
        name: 'Name of the API Key',
        key: 'API Key',
        description: 'Description'
      },
      leadReportTypesAdmin: {
        title: 'Lead Report Types',
        new: 'New type of report',
        create: 'Create type of report',
        edit: 'Edit type of report',
        type: 'Type',
        icon: 'Icon'
      }
    }
  }
}
