import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { UserNotificationService } from '@/application/services/notification/userNotification/userNotification.service'
import { Criteria } from '@/application/services/common/criteria'
import { CriteriaFilterOperator } from '@/application/services/common/criteriaFilterOperator'

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref<object[]>([])
  const toggleShowRead = ref<boolean>(true)
  const intervalId = ref<number | null>(null)

  watch(toggleShowRead, () => {
    load()
  })

  // GETTERS
  const getNotifications = (): object | undefined => notifications.value

  //setters
  const setToggleShowRead = (value: boolean) => {
    toggleShowRead.value = value
  }

  // ACTIONS
  const load = () => {
    let filters = {}
    if (toggleShowRead.value) {
      filters = {
        read: {
          operator: CriteriaFilterOperator.EQ,
          value: '0'
        }
      }
    }
    UserNotificationService.search(new Criteria(filters, [{ key: 'created_at', order: 'desc' }], 0, 99999)).then(
      response => {
        notifications.value = response.items
      }
    )
  }

  const startReload = () => {
    intervalId.value = setInterval(
      () => {
        load()
      },
      5 * 60 * 1000
    )
  }

  const stopReload = () => {
    if (intervalId.value) {
      clearInterval(intervalId.value)
    }
  }

  return {
    getNotifications,
    setToggleShowRead,

    load,
    toggleShowRead,
    startReload,
    stopReload,
    notifications
  }
})
