<template>
  <v-card
    :prepend-icon="prependIcon"
    :title="title"
    :subtitle="subtitle"
    :append-icon="appendIcon"
    :class="classes"
    :image="image"
    @click="emit('click')"
    density="compact"
    :variant="type"
    elevation="3"
    :no-action="noAction"
    :ripple="!noAction"
    :to="to"
  >
    <v-card-title v-if="slots.title">
      <slot name="title"></slot>
    </v-card-title>

    <v-card-subtitle v-if="slots.subtitle">
      <slot name="subtitle"></slot>
    </v-card-subtitle>

    <slot></slot>

    <v-card-actions v-if="slots.actions">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue'

defineOptions({
  name: 'Card',
  inheritAttrs: false,
  customOptions: {}
})

const props = defineProps<{
  prependIcon?: string
  title?: string | number
  subtitle?: string | number
  appendIcon?: string
  image?: string
  class?: string
  type?: 'elevated' | 'outlined' | 'text'
  noAction?: boolean
  to?: string | object
}>()

const slots = useSlots()
const emit = defineEmits(['click'])

const classes = computed(() => `lca-card ${props.class ?? ''} ${props.noAction ? 'no-action' : ''}`)
</script>

<style lang="scss">
.lca-card {
  &.no-action {
    &:hover {
      background-color: transparent;
      cursor: default;

      > .v-card__overlay {
        opacity: 0;
      }
    }
  }
}
</style>
