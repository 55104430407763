import { RouteRecordRaw } from 'vue-router'
import { PermissionList } from '@application/permisionList'

const paths: Array<RouteRecordRaw> = [
  {
    path: 'payment-orders',
    name: 'payment-orders-main',
    component: () => import('@vBackoffice/paymentOrder/MainPaymentOrder.vue'),
    children: [
      {
        path: '',
        name: 'payment-orders-list',
        meta: { permission: PermissionList.STUDENT_PAYMENTS_VIEW },
        component: () => import('@vBackoffice/paymentOrder/ListPaymentOrder.vue')
      },
      {
        path: ':id/student/:studentId',
        name: 'payment-orders-detail-all',
        meta: { permission: PermissionList.STUDENT_PAYMENTS_VIEW },
        component: () => import('@vBackoffice/paymentOrder/DetailsPaymentOrder.vue')
      }
    ]
  }
]

export default paths
