import { useDisplay } from 'vuetify'

export default class DeviceClass {
  isSmAndDown = (): boolean => useDisplay().smAndDown.value
  isSmAndUp = (): boolean => useDisplay().smAndUp.value

  isMdAndDown = (): boolean => useDisplay().mdAndDown.value
  isMdAndUp = (): boolean => useDisplay().mdAndUp.value

  isLgAndDown = (): boolean => useDisplay().lgAndDown.value
  isLgAndUp = (): boolean => useDisplay().lgAndUp.value

  isXlAndDown = (): boolean => useDisplay().xlAndDown.value
  isXlAndUp = (): boolean => useDisplay().xlAndUp.value

  height = (): number => useDisplay().height.value
  width = (): number => useDisplay().width.value
}

// xs = < 600px
// sm = 600px > < 960px
// md = 960px > < 1280px
// lg = 1280px > < 1920px
// xl = 1920px > < 2560px
// xxl = > 2560px
