<template>
  <v-navigation-drawer
    v-model="visible"
    :app="app"
    :expand-on-hover="expandOnHover"
    :rail="rail"
    :color="color"
    :permanent="permanent"
    :class="class"
    :location="location"
    :floating="floating"
    :width="width"
    :temporary="temporary"
    :scrim="scrim"
  >
    <slot></slot>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
defineOptions({
  name: 'NavigationDrawer',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  app?: boolean
  expandOnHover?: boolean
  rail?: boolean
  color?: string
  permanent?: boolean
  location?: 'start' | 'end' | 'left' | 'right' | 'top' | 'bottom'
  floating?: boolean
  width?: number | string
  class?: string
  temporary?: boolean
  scrim?: boolean
}

const props = defineProps<PropsType>()
const visible = defineModel<boolean>()
</script>
