import { type App, type Component, createApp } from 'vue'

export const getVAppRoot      = () => document.querySelector('[data-v-app]') as HTMLElement;
export const createContainer  = () => document.createElement('div');

interface NotifyOptions {
  component: Component,
  app: App,
  content: Object,
  status: string,
  options: object
}

interface NotifyCancelOptions {
  handleCancel: Boolean
}

export const mountComponent = ({ component, app, status, content, options }: NotifyOptions): Promise<any> => {
  const rootElement = getVAppRoot();
  const container   = createContainer();

  return new Promise((resolve, reject) => {
    const componentApp = createApp(component, {
      status,
      content,
      options,
      onSubmit: (value: any) => {
        resolve(value);
        onUnmounted(componentApp, rootElement, container)
      },
      onCancel: (value: any) => {
        // Para poder desactivar el cancel del Toast por ejemplo
        ((options as NotifyCancelOptions)?.handleCancel) ? reject(value) : resolve(value)
        onUnmounted(componentApp, rootElement, container)
      },
    });

    Object.assign(componentApp._context, app._context)

    rootElement.appendChild(container);
    componentApp.mount(container);
  })
}

const onUnmounted = (componentApp: App, rootElement: HTMLElement, container: HTMLDivElement) => {
  setTimeout(() => {
    componentApp.unmount();
    rootElement.removeChild(container)
  }, 400);
}
