<template>
  <v-list v-model:opened="opened" density="compact" :nav="nav" :class="{ divider: divider }">
    <slot></slot>
  </v-list>
</template>

<script setup lang="ts">
defineOptions({
  name: 'List',
  inheritAttrs: false,
  customOptions: {}
})

withDefaults(
  defineProps<{
    nav?: boolean
    divider?: boolean
  }>(),
  {
    nav: false,
    divider: false
  }
)

const opened = defineModel()
</script>

<style lang="scss">
.v-list:not(.v-list--nav) {
  padding: 0px 0px;
}

.v-list {
  &.divider {
    .v-list-item {
      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
}
</style>
