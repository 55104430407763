import type { MeyaThemeOptions } from './../application/interfaces/plugins/theme.interfaces'

// Icons
import '@fortawesome/fontawesome-free/css/all.css'

// Styles
import 'vuetify/styles'

// Vuetify
import { createVuetify as createBase } from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import { fa } from 'vuetify/iconsets/fa'
import { VBtn } from 'vuetify/components'
import { es, en } from 'vuetify/locale'

import baseTheme from './../application/themes/base.theme'

export function createVuetify(options?: MeyaThemeOptions) {
  let appTheme = baseTheme
  if (options && options?.theme) {
    appTheme = options?.theme as any
  }

  const vuetify = createBase({
    locale: {
      locale: options?.locale || 'en',
      messages: { es, en }
    },
    components: {
      ...components,
      ...labsComponents
    },
    directives,
    icons: {
      defaultSet: 'fa',
      aliases: {
        calendar: 'fa-solid fa-calendar',
        cancel: 'fa-solid fa-times',
        checkboxIndeterminate: 'fa-solid fa-minus-square',
        checkboxOff: 'fa-regular fa-square',
        checkboxOn: 'fa-solid fa-check-square',
        clear: 'fa-regular fa-circle-xmark',
        close: 'fa-solid fa-times',
        collapse: 'fa-solid fa-chevron-up',
        delete: 'fa-solid fa-trash',
        delimiter: 'fa-solid fa-circle',
        dropdown: 'fa-solid fa-caret-down',
        edit: 'fa-solid fa-pencil',
        expand: 'fa-solid fa-chevron-down',
        eyeDropper: 'fa-solid fa-eye-dropper',
        file: 'fa-solid fa-file',
        first: 'fa-solid fa-angle-double-left',
        info: 'fa-solid fa-info',
        last: 'fa-solid fa-angle-double-right',
        loading: 'fa-solid fa-spinner',
        menu: 'fa-solid fa-bars',
        minus: 'fa-solid fa-minus',
        next: 'fa-solid fa-angle-right',
        plus: 'fa-solid fa-plus',
        prev: 'fa-solid fa-chevron-left',
        radioOff: 'fa-solid fa-circle',
        radioOn: 'fa-solid fa-circle-dot',
        ratingEmpty: 'fa-regular fa-star',
        ratingFull: 'fa-solid fa-star',
        ratingHalf: 'fa-regular fa-star-half-stroke',
        sortAsc: 'fa-solid fa-arrow-up',
        sortDesc: 'fa-solid fa-arrow-down',
        subgroup: 'fa-solid fa-caret-down',
        success: 'fa-solid fa-check',
        treeviewCollapse: 'fa-solid fa-caret-down',
        treeviewExpand: 'fa-solid fa-caret-right',
        unfold: 'fa-solid fa-arrows-up-down',
        warning: 'fa-solid fa-exclamation-triangle'
      },
      sets: {
        fa
      }
    },
    aliases: {
      VBtnPrimary: VBtn,
      VBtnSecondary: VBtn
    },
    theme: {
      defaultTheme: 'appTheme',
      themes: {
        appTheme
      }
      /* options: {
        customProperties: true,
      }, */
    },
    defaults: {
      VList: {
        color: 'primary'
      },
      VAppBar: {
        color: 'primary'
      },
      VTextField: {
        variant: 'underlined',
        color: 'primary'
      },
      VSelect: {
        variant: 'underlined',
        color: 'primary'
      }
    }
  })

  return vuetify
}
