import 'vue3-toastify/dist/index.css'

import { toast as _toast, updateGlobalOptions } from 'vue3-toastify'
export const toast = _toast

updateGlobalOptions({
  position: 'top-right',
  autoClose: 2000,
  theme: 'colored',
  dangerouslyHTMLString: true
})
