import { RouteRecordRaw } from 'vue-router'
import { PermissionList } from '@application/permisionList'

const paths: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'manuals-main',
    component: () => import('@vBackoffice/manual/MainManuals.vue'),
    children: [
      {
        path: 'manual',
        name: 'manuals-list',
        meta: { permission: PermissionList.KNOWLEDGE_MANUALS_VIEW },
        component: () => import('@vBackoffice/manual/ListManuals.vue')
      },
      {
        path: 'manual/:id',
        name: 'manual-detail',
        meta: { permission: PermissionList.KNOWLEDGE_MANUALS_VIEW },
        component: () => import('@vBackoffice/manual/DetailsManual.vue')
      }
    ]
  }
]

export default paths
