<template>
  <v-text-field
    v-model="model"
    :active="show"
    :focused="show"
    :label="label"
    readonly
    density="compact"
    :disabled="disabled"
    :rules="rules"
    class="lca-input-icon-picker"
    :class="class"
    hide-details
  >
    <template #prepend>
      <div class="icon-picker">
        <v-icon :icon="getIconComponent"></v-icon>
      </div>

      <v-dialog v-model="show" activator="parent" class="lca-input-icon-picker-dialog" :width="getWidthModal">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              prepend-icon="fa-solid fa-search"
              :label="$t('core.search')"
              outlined
              dense
              clearable
            >
              <template #append>
                <v-icon v-if="model && clearable" icon="fa-solid fa-trash" @click="model = ''" class="m-r-20" />
                <!-- <v-icon v-if="model" :icon="model" class="m-r-10"/> -->
              </template>
            </v-text-field>
          </v-card-title>

          <div class="icon-container">
            <div v-for="icon in iconsListAvailable" :key="icon" class="icon-item">
              <div :class="{ icon: true, active: icon == model }" @click="model = icon">
                <i :class="icon"></i>
              </div>
            </div>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="show = false">{{ $t('core.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import iconsList from './icons'
import DeviceClass from './../../../application/services/device.class'

defineOptions({
  name: 'InputIconPicker',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  label?: string
  clearable?: boolean
  disabled?: boolean
  rules?: any[]
  class?: string
}
const props = defineProps<PropsType>()

const model = defineModel()
const show = ref(false)
const search = ref('')
const device = new DeviceClass()

const iconsListAvailable = computed(() => {
  if (!search.value || search.value.trim().length == 0) {
    return iconsList
  }
  return iconsList.filter((icon: string) => icon.includes(search.value.trim()))
})

const getIconComponent = computed(() => {
  if (model.value) {
    return model.value
  }
  return 'fa-solid fa-question'
})

const getWidthModal = computed(() => {
  if (device.isSmAndDown()) {
    return '90%'
  } else if (device.isMdAndDown()) {
    return '600'
  } else if (device.isLgAndDown()) {
    return '800'
  } else if (device.isLgAndUp()) {
    return '1024'
  } else {
    return 'auto'
  }
})
</script>

<style lang="scss">
.lca-input-icon-picker {
  &.v-input--density-compact.v-input--plain-underlined {
    display: inline-block;

    .v-input__prepend {
      padding-top: 0px;
      margin-inline-end: 0px;

      .icon-picker {
        border: 1px solid transparent;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 50%;
        padding: 6px;

        &:hover {
          background-color: var(--grey-hover);
          cursor: pointer;
        }
      }
    }

    &.v-input--error {
      .v-input__prepend {
        .icon-picker {
          border: 1px solid rgb(var(--v-theme-error));
        }
      }
    }
  }

  .v-input__control {
    display: none;
  }
}

.lca-input-icon-picker-dialog {
  .icon-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    gap: 10px;
    max-height: 60vh;
    overflow-y: scroll;
    padding: 12px;
    padding-top: 0px;

    .icon-item {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 35px;
        height: 35px;
        border: 1px solid #959595;
        border-radius: 50%;
        margin: 0 auto;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 16px;

        &:hover {
          cursor: pointer;

          &:not(.active) {
            background-color: var(--grey-hover);
            color: var(--primary-color);
          }
        }

        &.active {
          background-color: var(--primary-color);
        }
      }
    }
  }
}
</style>
