<template>
  <v-app-bar class="lca-app-bar" density="compact" :nav-icon="navIcon" :class="class" :hide-version="hideVersion">
    <div class="lca-app-bar-content">
      <div class="title">
        <v-btn v-if="navIcon" class="nav-icon" icon="$menu" @click="emit('click:nav-icon')" />
        <slot name="title"></slot>
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
      <div class="append">
        <slot name="append"></slot>
      </div>
    </div>

    <template v-slot:extension v-if="slots.extension">
      <slot name="extension"></slot>
    </template>
  </v-app-bar>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

defineOptions({
  name: 'AppBar',
  inheritAttrs: false,
  customOptions: {}
})

withDefaults(
  defineProps<{
    class?: string
    navIcon?: boolean
    hideVersion?: boolean
  }>(),
  {
    hideVersion: false
  }
)

const emit = defineEmits(['click:nav-icon'])
const slots = useSlots()
</script>

<style lang="scss">
.lca-app-bar {
  .lca-app-bar-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row;
    width: 100%;
    padding: 6px 12px;

    .v-btn.v-btn--icon {
      width: calc(var(--v-btn-height));
      height: calc(var(--v-btn-height));
    }

    > div {
      display: flex;
      align-items: center;
    }

    .title {
      flex: 2;
      justify-content: flex-start;

      @media (max-width: 960px) {
        width: 100%;
      }

      .nav-icon {
        margin-left: -6px;
        margin-right: 6px;
      }
    }

    .body {
      flex: 4;
      justify-content: center;
      padding: 0px 6px;

      @media (max-width: 960px) {
        display: none;
      }
    }

    .append {
      flex: 1;
      justify-content: flex-end;

      .v-btn {
        margin-left: 6px;
      }
    }
  }
}
</style>
