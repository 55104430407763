<template>
  <img :src="contentImg" :style="getStyle()" @wheel.alt="changeWheel" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

defineOptions({
  name: 'ImageViewer',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  content: string
  zoom: number
}
const props = defineProps<PropsType>()
const emit = defineEmits(['zoomIn', 'zoomOut'])

const contentImg = computed(() => {
  return URL.createObjectURL(props.content)
})

const changeWheel = (event: WheelEvent) => {
  if (event.deltaY > 0) {
    emit('zoomOut')
  } else {
    emit('zoomIn')
  }
}

const getStyle = () => {
  let style = `width: ${props.zoom}%;`
  if (props.zoom >= 100) {
    style += ` margin-right:20px;`
  }
  return style
}
</script>

<style lang="scss">
img {
  height: auto;
}
</style>
