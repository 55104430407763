import { RouteRecordRaw } from 'vue-router'

import BackofficeRoutes from './backoffice'
import EmpolyeeRoutes from './employee'
import StudentRoutes from './student'

const paths: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'base',
    component: () => import('@views/MainView.vue'),
    children: [
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@views/common/NotFoundView.vue')
      },
      {
        path: 'not-permission',
        name: 'not-permission',
        component: () => import('@views/common/NotPermissionView.vue')
      },
      ...BackofficeRoutes,
      ...EmpolyeeRoutes,
      ...StudentRoutes
    ]
  }
]

export default paths
