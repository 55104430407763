export enum PermissionList {
  // ROLES
  ACCOUNT_ROLES_VIEW = 'ACCOUNT_ROLES_VIEW',
  ACCOUNT_ROLES_CREATE = 'ACCOUNT_ROLES_CREATE',
  ACCOUNT_ROLES_UPDATE = 'ACCOUNT_ROLES_UPDATE',
  ACCOUNT_ROLES_DELETE = 'ACCOUNT_ROLES_DELETE',

  // ACCOUNT USERS //
  ACCOUNT_USERS_VIEW = 'ACCOUNT_USERS_VIEW',
  ACCOUNT_USERS_CREATE = 'ACCOUNT_USERS_CREATE',
  ACCOUNT_USERS_UPDATE = 'ACCOUNT_USERS_UPDATE',
  ACCOUNT_USERS_DELETE = 'ACCOUNT_USERS_DELETE',

  // EMPLOYEES //
  EMPLOYEES_VIEW = 'EMPLOYEES_VIEW',
  EMPLOYEES_CREATE = 'EMPLOYEES_CREATE',
  EMPLOYEES_UPDATE = 'EMPLOYEES_UPDATE',
  EMPLOYEES_DELETE = 'EMPLOYEES_DELETE',

  // KNOWLEDGE //
  KNOWLEDGE_VIEW = 'KNOWLEDGE_VIEW',
  KNOWLEDGE_CREATE = 'KNOWLEDGE_CREATE',
  KNOWLEDGE_UPDATE = 'KNOWLEDGE_UPDATE',
  KNOWLEDGE_DELETE = 'KNOWLEDGE_DELETE',

  // KNOWLEDGE MANUALS //
  KNOWLEDGE_MANUALS_VIEW = 'KNOWLEDGE_MANUALS_VIEW',
  KNOWLEDGE_MANUALS_CREATE = 'KNOWLEDGE_MANUALS_CREATE',
  KNOWLEDGE_MANUALS_UPDATE = 'KNOWLEDGE_MANUALS_UPDATE',
  KNOWLEDGE_MANUALS_DELETE = 'KNOWLEDGE_MANUALS_DELETE',
  KNOWLEDGE_MANUAL_VERSIONS_DELETE = 'KNOWLEDGE_MANUAL_VERSIONS_DELETE',

  // KNOWLEDGE PRACTICES //
  KNOWLEDGE_PRACTICES_VIEW = 'KNOWLEDGE_PRACTICES_VIEW',
  KNOWLEDGE_PRACTICES_CREATE = 'KNOWLEDGE_PRACTICES_CREATE',
  KNOWLEDGE_PRACTICES_UPDATE = 'KNOWLEDGE_PRACTICES_UPDATE',
  KNOWLEDGE_PRACTICES_DELETE = 'KNOWLEDGE_PRACTICES_DELETE',

  // PROMOTIONS //
  PROMOTIONS_VIEW = 'PROMOTIONS_VIEW',
  PROMOTIONS_CREATE = 'PROMOTIONS_CREATE',
  PROMOTIONS_UPDATE = 'PROMOTIONS_UPDATE',
  PROMOTIONS_DELETE = 'PROMOTIONS_DELETE',
  PROMOTION_SCHEDULES_MANAGEMENT = 'PROMOTION_SCHEDULES_MANAGEMENT',

  // STUDENTS //
  STUDENTS_VIEW = 'STUDENTS_VIEW',
  STUDENTS_CREATE = 'STUDENTS_CREATE',
  STUDENTS_UPDATE = 'STUDENTS_UPDATE',
  STUDENTS_DELETE = 'STUDENTS_DELETE',
  STUDENTS_GENERATE_AGREEMENT = 'STUDENTS_GENERATE_AGREEMENT',
  Students_VIEW_F06 = 'Students_VIEW_F06',
  Students_GENERATE_F06 = 'Students_GENERATE_F06',

  // STUDENT PAYMENTS //
  STUDENT_PAYMENTS_VIEW = 'STUDENT_PAYMENTS_VIEW',
  STUDENT_PAYMENTS_CREATE = 'STUDENT_PAYMENTS_CREATE',
  STUDENT_PAYMENTS_UPDATE = 'STUDENT_PAYMENTS_UPDATE',
  STUDENT_PAYMENTS_DELETE = 'STUDENT_PAYMENTS_DELETE',

  // LEADS //
  LEADS_VIEW = 'LEADS_VIEW',
  LEADS_CREATE = 'LEADS_CREATE',
  LEADS_UPDATE = 'LEADS_UPDATE',
  LEADS_DELETE = 'LEADS_DELETE',

  // API KEYS //
  API_KEYS_VIEW = 'API_KEYS_VIEW',
  API_KEYS_CREATE = 'API_KEYS_CREATE',
  API_KEYS_UPDATE = 'API_KEYS_UPDATE',
  API_KEYS_DELETE = 'API_KEYS_DELETE'
}
