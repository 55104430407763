<template>
  <lca-app-bar :nav-icon="device.isSmAndDown()" @click:nav-icon="menuSidebarStore.toggle()">
    <template #title>
      <lca-button v-if="btnBack" text icon="fa-solid fa-chevron-left" @click="goBack()" />
      <slot name="title"></slot>
    </template>

    <template #append>
      <div>
        <lca-button text icon="fa-solid fa-bell" @click="toggleNotificationDrawer()"></lca-button>
        <span class="badge badge-light">{{ notificationStore?.notifications?.length ?? 0 }}</span>
      </div>
      <lca-button text icon="fa-regular fa-note-sticky" @click="toggleNotesDrawer()" v-if="!hideNotes"></lca-button>
      <slot name="append"></slot>
    </template>

    <template v-slot:extension v-if="slots.extension">
      <slot name="extension"></slot>
    </template>
  </lca-app-bar>

  <lca-navigation-drawer
    v-model="showNotesDrawer"
    location="right"
    width="500"
    :temporary="true"
    :scrim="false"
    v-if="!hideNotes"
  >
    <lca-container class="overflow-scroll notes-container">
      <single-note v-for="note in userStore.getNotes()" :key="note.id" :note="note" />
    </lca-container>
    <div class="text-center p-r-10 p-l-10 m-t-5">
      <lca-button type="primary" @click="openTitleModal" class="w-100">{{ t('module.notes.create') }}</lca-button>
    </div>
  </lca-navigation-drawer>

  <lca-navigation-drawer v-model="showNotificationDrawer" location="right" width="300" :temporary="true" :scrim="false">
    <lca-container v-if="notificationStore.notifications" class="overflow-scroll notes-container">
      <lca-switch
        class="switch-permission m-l-10"
        v-model="notificationStore.toggleShowRead"
        color="primary"
        :hide-details="true"
        :label="$t('module.notifications.showNotRead')"
      />
      <menu-notification
        v-for="notification in notificationStore.notifications"
        @reloadNotifications="notificationStore.load()"
        :key="notification.id"
        :notification="UserNotification.fromAPI(notification)"
      />
      <lca-button type="primary" @click="notificationStore.load()" class="w-100 m-t-5">{{
        t('core.update')
      }}</lca-button>
    </lca-container>
  </lca-navigation-drawer>

  <lca-modal
    v-model="show"
    :title="$t('module.notes.create')"
    :btn-success-title="$t('core.create')"
    :btn-disabled="loadingForm"
    @success="createNote()"
    width="40%"
  >
    <lca-form ref="noteForm">
      <lca-input
        v-model="noteTitle"
        :disabled="loadingForm"
        :rules="mandatoryFieldRule"
        :label="$t('module.notes.new')"
      ></lca-input>
    </lca-form>
  </lca-modal>
</template>

<script setup lang="ts">
import { useSlots, ref } from 'vue'
import { useRouter } from 'vue-router'
import { device, toast, useI18n } from 'meya'
import SingleNote from '@/components/SingleNote.vue'
import MenuNotification from '@/components/MenuNotification.vue'
import { useMenuSidebarStore } from '@stores/components/menuSidebar.store'
import { useUserStore } from '@/store/account/user.store'
import { useNotificationStore } from '@/store/notification/notificartion.store'
import { NotesService } from '@service/backoffice/account/notes.service'
import { UserNotification } from '@entity/notification/userNotification/userNotification.entity'

const props = withDefaults(
  defineProps<{
    btnBack?: string | object | boolean
    hideNotes?: boolean
  }>(),
  {
    btnBack: false,
    hideNotes: false
  }
)

const { t } = useI18n()
const router = useRouter()
const menuSidebarStore = useMenuSidebarStore()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const slots = useSlots()

const goBack = () => {
  if (props.btnBack) {
    if (typeof props.btnBack === 'string') {
      router.push({ name: props.btnBack })
    } else if (typeof props.btnBack === 'boolean') {
      router.go(-1)
    } else {
      router.push(props.btnBack)
    }
  }
}

const showNotesDrawer = ref<boolean>(false)
const showNotificationDrawer = ref<boolean>(false)
const noteForm = ref(null)
const toggleNotesDrawer = () => {
  showNotesDrawer.value = !showNotesDrawer.value
}

const toggleNotificationDrawer = () => {
  showNotificationDrawer.value = !showNotificationDrawer.value
}
const mandatoryFieldRule = [(v: string) => !!v || t('core.validations.fieldMandatory')]
const show = ref<boolean>(false)
const noteTitle = ref('')
const loadingForm = ref<boolean>(false)
const openTitleModal = () => {
  show.value = true
}
const createNote = async () => {
  const { valid } = await noteForm.value.validate()
  if (valid) {
    loadingForm.value = true
    NotesService.create(userStore.getUserId(), noteTitle.value)
      .then(() => {
        show.value = false
        toast.success(t('module.notes.success.create'))
        noteTitle.value = ''
      })
      .finally(() => {
        loadingForm.value = false
      })
  }
}
</script>
<style scoped>
.notes-container {
  height: calc(100% - 55px);
}
.badge {
  position: relative;
  top: -10px;
  left: -10px;
}
</style>

<style lang="scss" scoped>
.menu-top-title {
  font-size: 18px;
}
</style>
