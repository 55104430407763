import { RouteRecordRaw } from 'vue-router'
import { PermissionList } from '@application/permisionList'

const paths: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'module-practice-main',
    component: () => import('@vBackoffice/modulePractice/MainModulePractice.vue'),
    children: [
      {
        path: 'modulePractice',
        name: 'module-practice',
        meta: { permission: PermissionList.KNOWLEDGE_PRACTICES_VIEW },
        component: () => import('@vBackoffice/modulePractice/ListModulePractice.vue')
      }
    ]
  }
]

export default paths
