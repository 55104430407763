import { createI18n as createBase, useI18n as useI18nBase } from 'vue-i18n'
import type { MeyaTradsOptions } from './../application/interfaces/plugins/i18n.interfaces'

export function createI18n(options?: MeyaTradsOptions) {
  const i18n = createBase({
    globalInjection: true,
    legacy: false,
    locale: options?.default ?? 'en',
    fallbackLocale: options?.default ?? 'en'
  })

  if (options && options.trads !== undefined) {
    for (const lang in options.trads) {
      //console.log(`Define language: ${lang}`)
      i18n.global.setLocaleMessage(lang, options.trads[lang])
    }
  }

  return i18n
}

export function useI18n(opt: any) {
  return useI18nBase(opt)
}
