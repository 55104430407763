<template>
  <v-expansion-panels v-model="panels" :color="type" :multiple="multiple">
    <slot></slot>
  </v-expansion-panels>
</template>

<script setup lang="ts">
defineOptions({
  name: 'ExpansionPanels',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  type?: string
  multiple?: boolean
}

const props = defineProps<PropsType>()
const panels = defineModel()
</script>
