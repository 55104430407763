import { ScopeEnum } from '@/application/entities/common/login/scope.enum'
import PromotionRoutes from './modules/promotion'

export default [
  {
    path: 'student',
    name: 'student-base',
    meta: { scope: ScopeEnum.STUDENT },
    component: () => import('@vStudent/StudentView.vue'),
    children: [
      {
        path: 'home',
        name: 'student-home',
        component: () => import('@vStudent/dashboard/DashboardView.vue')
      },
      ...PromotionRoutes
    ]
  }
]
