<template>
  <v-autocomplete
    v-model="model"
    :label="label"
    :items="items"
    :item-value="value"
    :item-title="title"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :auto-select-first="autoSelectFirst"
    :density="density"
    variant="underlined"
    @update:modelValue="onChange"
    v-bind="$attrs"
  >
    <slot></slot>
  </v-autocomplete>
</template>

<script setup lang="ts">
defineOptions({
  name: 'Select',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  label?: string
  items: any[]
  value?: string
  title?: string
  rules?: any[]
  disabled?: boolean
  readonly?: boolean
  autoSelectFirst?: boolean
  density?: 'default' | 'comfortable' | 'compact' | undefined
}

const props = withDefaults(defineProps<PropsType>(), {
  density: 'compact'
})

const model = defineModel()
const emit = defineEmits(['change'])

const onChange = () => {
  emit('change', model.value)
}
</script>
