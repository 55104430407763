<template>
  <div class="lca-input-money">
    <v-text-field
      ref="money-input"
      v-model="amount"
      :label="label"
      density="compact"
      :clearable="clearable"
      :disabled="disabled"
      type="number"
    ></v-text-field>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, watch } from 'vue'
import { Money } from '../../application/valueObjects/money'

defineOptions({
  name: 'InputMoney',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  label?: string
  clearable?: boolean
  disabled?: boolean
}

const props = defineProps<PropsType>()
const moneyValue = defineModel<Money>()

const amount = ref<number>()
const currency = ref<string>('EUR')

onBeforeMount(() => {
  if (moneyValue.value) {
    if (moneyValue.value instanceof Money) {
      amount.value = moneyValue.value.getAmount()
      currency.value = moneyValue.value.getCurrency()
    } else {
      amount.value = moneyValue.value.amount
      currency.value = moneyValue.value.currency
    }
  }
})

watch([amount, currency], () => {
  moneyValue.value = new Money(parseFloat(amount.value), currency.value)
})
</script>

<style lang="scss" scoped>
.lca-input-money {
  display: flex;
  justify-content: flex-start;
}
</style>
