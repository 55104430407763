import { BaseService } from '@service/common/base.services'
import { UserNotification } from '@entity/notification/userNotification/userNotification.entity'
import { Uuid } from 'meya'

export class UserNotificationService extends BaseService {
  protected static apiDefaultSearchPath: string = '/v1/notification/userNotification/search'

  static read(id: Uuid): Promise<UserNotification> {
    return this.post(`v1/notification/userNotification/${id.getValue()}/read`).then(data =>
      UserNotification.fromAPI(data)
    )
  }
}
