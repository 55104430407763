<template>
  <v-checkbox
    v-model="modelValue"
    :color="color"
    :append-icon="appendIcon"
    :density="density"
    :rules="rules"
    :disabled="disabled"
    :name="name"
    :label="label"
    :readonly="readonly"
    :hide-details="hideDetails"
  >
    <slot></slot>
  </v-checkbox>
</template>

<script setup lang="ts">
defineOptions({
  name: 'Checkbox',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  color?: string
  appendIcon?: string
  density?: 'default' | 'comfortable' | 'compact'
  rules?: any
  disabled?: boolean
  name?: string
  label?: string
  readonly?: boolean
  hideDetails?: boolean
}

const props = defineProps<PropsType>()
const modelValue = defineModel()
</script>
