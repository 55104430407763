const es = {
  core: {
    welcome: 'Bienvenido {name}!!',
    yes: 'Si',
    no: 'No',
    save: 'Guardar',
    continue: 'Continuar',
    add: 'Añadir',
    user: 'Usuario',
    changePassword: 'Cambiar contraseña',
    changeLanguage: 'Cambiar idioma',
    password: 'Contraseña',
    lastPassword: 'Contraseña anterior',
    newPassword: 'Nueva contraseña',
    rePassword: 'Repetir la contraseña',
    watch: 'Ver',
    create: 'Crear',
    notify: 'Notificar',
    show: 'Ver',
    close: 'Cerrar',
    search: 'Buscar',
    searchBy: 'Buscar por {items}',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    update: 'Actualizar',
    updateFile: 'Actualizar fichero',
    edit: 'Editar',
    image: 'Imagen',
    file: 'Archivo',
    extension: 'Extensión',
    size: 'Tamaño',
    email: 'Email',
    name: 'Nombre',
    surname: 'Apellidos',
    rol: 'Rol',
    roles: 'Roles',
    changeNewRole: 'Cambiar a nuevo rol',
    id: 'Id',
    itemsPerPage: 'Items por página',
    cleanFilters: 'Limpiar filtros',
    hide: 'Ocultar',
    others: 'Otros',
    language: 'Idioma',
    loading: 'Cargando...',
    clean: 'Limpiar',
    today: 'Hoy',
    countryPrefix: 'Prefijo',
    active: 'Activo',
    inactive: 'Inactivo',
    back: 'Volver',
    notPermissionPage: 'No tienes permisos para acceder a esta página',
    notFoundPage: 'Página no encontrada',
    go: 'Ir',
    generate: 'Generar',
    copyToClipboard: 'Copiar al portapapeles',
    copiedToClipboard: 'Copiado al portapapeles',
    comments: 'Comentarios',
    description: 'Descripción',
    date: 'Fecha',
    integrations: 'Integraciones',
    key: 'Clave',
    expirationDate: 'Fecha de expiración',
    noVersionsAvailable: 'No hay versiones disponibles',
    hours: 'Horas',
    review: 'Revisión',
    leadReportTypes: 'Tipos de reporte de Lead',
    leads: 'Leads',
    status: 'Estado',
    report: 'Reporte',
    type: 'Tipo',
    validations: {
      nameMandatory: 'El nombre es obligatorio',
      userMandatory: 'Usuario es obligatorio',
      emailMandatory: 'Email es obligatiorio',
      passwordMandatory: 'Contraseña es obligatoria',
      roleMandatory: 'Rol es obligatoria',
      fieldMandatory: 'Campo obligatorio',
      urlInvalid: 'URL no válida',
      rePass: 'Las contraseñas no coinciden',
      minPass: 'La contraseña debe tener al menos 8 caracteres',
      customPassword:
        'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial'
    },
    toast: {
      success: {
        create: 'Creado con éxito',
        notified: 'Notificado',
        save: 'Guardado con éxito',
        add: 'Añadido con éxito',
        update: 'Actualizado con éxito',
        delete: 'Eliminado con éxito'
      },
      error: 'Error en la operación',
      errorDate: 'La fecha no puede ser mayor que la fecha actual',
      errorNotData: 'No has seleccionado ningún dato'
    },
    confirmation: {
      delete: '¿Estás seguro que quieres eliminarlo?',
      create: '¿Estás seguro que quieres crearlo?'
    },
    calendar: {
      days: {
        weekOfDays: 'Días de la semana',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo'
      }
    }
  },
  scope: {
    backoffice: 'Administración',
    employee: 'Instructor',
    student: 'Estudiante',
    loginAs: 'Acceder como:',
    notScope: 'No tienes ningún rol asignado',
    change: 'Cambiar Rol'
  },
  sidebar: {
    contextual: {
      myNotes: 'Mis Notas',
      profile: 'Perfil',
      help: 'Ayuda',
      closeSession: 'Cerrar Sesión'
    },
    setting: 'Configuración',
    leads: 'Leads'
  },
  module: {
    login: {
      login: 'Entrar',
      recoveryPassword: 'Recuperar Contraseña',
      recoveryPasswordSuccess: 'Se ha enviado un email para recuperar la contraseña'
    },
    dashboard: {
      title: 'Inicio',
      dashboard: 'Panel de control',
      modules: 'Módulos'
    },
    knowledge: {
      title: 'Módulos',
      lessons: 'Número de clases',
      lastUpdate: 'Ultima Actualización',

      newModule: 'Añadir Módulo',
      newModuleTitle: 'Nuevo Módulo',
      editModuleTitle: 'Editar Módulo',

      newSubmodule: 'Añadir Submódulo',
      newSubmoduleTitle: 'Nuevo Submódulo',
      editSubmoduleTitle: 'Editar Submódulo',
      listSubmodule: 'Listado de Submódulos',
      noSubmoduleCreated: 'No se ha creado ningún submódulo',

      newSubjectMatter: 'Añadir Materia',
      newSubjectMatterTitle: 'Nueva Materia',
      editSubjectMatterTitle: 'Editar Materia',
      listSubjectMatters: 'Listado de Materias',

      newLesson: 'Añadir Clase',
      newLessonTitle: 'Nueva Clase',
      editLessonTitle: 'Editar Clase',
      listLesson: 'Listado de Clases',

      manuals: {
        title: 'Manual',
        newManual: 'Añadir Manual',
        editManual: 'Editar Manual',
        showManual: 'Ver Manual'
      },
      manualVersions: {
        title: 'Versiones del manual',
        newManualVersion: 'Añadir Versión del manual',
        editManualVersion: 'Editar Versión del manual',
        showManualVersion: 'Ver Versión del manual',
        edition: 'Edición',
        revision: 'Revisión',
        editionDate: 'Fecha de edición'
      },

      module: 'Módulo',
      submodule: 'Submódulo',
      submodules: 'Submódulos',
      subjectMatter: 'Materia',
      subjectMatters: 'Materias',

      startSession: 'Comenzar Sesión',
      endSession: 'Finalizar Sesión',
      detailsSession: 'Detalles de la Sesión',
      finishedSession: 'Sesión Finalizada',
      savedAttendance: 'Asistencia guardada',
      savedSubjectMatters: 'Materias guardadas',

      modalModule: {
        title: 'Crear Módulo',
        name: 'Nombre del Módulo',
        lessons: 'Número de clases'
      },
      practice: {
        title: 'Prácticas',
        new: 'Nueva Práctica',
        create: 'Crear Práctica',
        edit: 'Editar Práctica',
        info: 'Información de la Práctica',
        list: 'Listado de Prácticas',
        dueDate: 'Fecha de entrega',
        exam: 'Examen',
        examDocument: 'Documento del examen',
        qualification: 'Calificación',
        details: {
          name: 'Nombre de la práctica',
          description: 'Descripción',
          sessions: 'Sesiones',
          groups: 'Grupos',
          isDefault: 'Por defecto',
          document: 'Documento',
          viewDoc: 'Ver documento'
        },
        review: {
          title: 'Revision de una práctica',
          new: 'Nueva revisión',
          info: 'Información de la revisión',
          create: 'Crear revisión',
          edit: 'Editar revisión',
          list: 'Listado de revisiones',
          sessionList: 'Listado de sesiones',
          sessionEdit: 'Editar sesión',
          sessionCreate: 'Crear sesión',
          warning: 'El número de sesiones no se ha alcanzado',
          groupEdit: 'Editar grupo',
          groupCreate: 'Crear grupo'
        }
      }
    },
    setting: {
      title: 'Configuración',

      validations: {
        userMandatory: 'Tipo de evento obligatiorio',
        passwordMandatory: 'Contraseña obligatoria'
      },
      userAdmin: {
        title: 'Administración de usuarios',
        new: 'Nuevo usuario',
        create: 'Crear usuario',
        edit: 'Editar usuario',
        roleType: 'Tipo de rol'
      },
      rolesAdmin: {
        title: 'Administración de roles',
        new: 'Nuevo rol',
        create: 'Crear rol',
        edit: 'Editar rol',
        name: 'Nombre del rol'
      },
      leadReportTypesAdmin: {
        title: 'Administración de tipos de reporte de Lead',
        new: 'Nuevo tipo de reporte de Lead',
        create: 'Crear tipo de reporte de Lead',
        edit: 'Editar tipo de reporte de Lead',
        type: 'Tipo de reporte',
        icon: 'Icono'
      },
      apiKeysAdmin: {
        title: 'Administración de claves API',
        new: 'Nueva clave API',
        create: 'Crear clave API',
        edit: 'Editar clave API',
        name: 'Nombre de la clave',
        key: 'Clave',
        description: 'Descripción'
      }
    },
    employee: {
      title: 'Instructores',
      nameEmployee: 'Nombre del instructor',
      new: 'Nuevo instructor',
      create: 'Crear instructor',
      edit: 'Editar instructor',
      info: 'Información del instructor',
      addCredentials: 'Generar credenciales de acceso ',
      credentialsGenerated: 'Credenciales generadas',
      copiedPasswordToClipboard: 'Contraseña copiada al portapapeles',
      details: {
        code: 'Codigo de instructor',
        dni: 'DNI / NIE',
        photo: 'Foto del DNI / NIE',
        phone: 'Teléfonos de contacto',
        address: 'Direcciones de contacto',
        birthdate: 'Fecha de nacimiento',
        tshirtSize: 'Talla de camiseta',
        jacketSize: 'Talla de chaqueta',
        pantsSize: 'Talla de pantalón',
        cv: 'CV',
        comments: 'Comentarios',
        prefix: 'Prefijo del telefono',
        number: 'Numero de telefono',
        isMain: 'Contacto principal',
        idCardNumberFileUpdated: 'Fichero actualizado'
      },
      addresses: {
        address: 'Dirección',
        street: 'Calle',
        number: 'Número',
        floor: 'Piso',
        door: 'Puerta',
        city: 'Población',
        state: 'Provincia',
        zipCode: 'Código postal',
        country: 'País',
        bonus: 'portal, escalera, etc...'
      },
      workExperience: {
        title: 'Experiencia Laboral',
        new: 'Nueva Experiencia laboral',
        edit: 'Editar',
        company: 'Empresa',
        position: 'Puesto',
        startDate: 'Fecha de Inicio',
        endDate: 'Fecha Fin',
        isCurrentJob: 'Trabaja aqui actualmente',
        description: 'Description',
        yes: 'si',
        no: 'no',
        endDataError: 'No se puede introducir una fecha anterior a la de comienzo'
      },
      postEmployeeTraining: {
        title: 'Formación de la empresa',
        name: 'Nombre',
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de fin',
        isCompleted: 'Completado',
        organization: 'Organización',
        quality: 'Calidad',
        description: 'Descripción',
        document: 'Documento',
        replanning: 'Replanificación',
        newStartDate: 'Nueva fecha de inicio',
        newEndDate: 'Nueva fecha de fin',
        reasons: 'Motivos'
      },
      replanning: {
        errorDate: 'La nueva fecha de inicio no puede ser mayor o igual que la nueva fecha de fin'
      },
      previousEmployeeTraining: {
        title: 'Formación previa del instructor',
        name: 'Nombre',
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de fin',
        organization: 'Organización',
        quality: 'Calidad',
        description: 'Descripción',
        document: 'Documento',
        reasons: 'Motivos'
      },
      employeeModule: {
        examiner: 'Examinador',
        instructor: 'Instructor',
        evaluator: 'Evaluador'
      }
    },
    student: {
      title: 'Estudiantes',
      new: 'Nuevo estudiante',
      create: 'Crear estudiante',
      edit: 'Editar estudiante',
      info: 'Información del estudiante',
      listStudent: 'Listado de estudiantes',
      addStudent: 'Añadir estudiante',
      deleteStudent: 'Eliminar estudiante',
      addCredentials: 'Generar credenciales de acceso ',
      credentialsGenerated: 'Credenciales generadas',
      generateAgreement: 'Generar el contrato',
      copiedPasswordToClipboard: 'Contraseña copiada al portapapeles',
      generateF06: 'Generar el documento F06',
      showF06: 'Ver el documento F06',
      document: {
        title: 'Documentos',
        new: 'Nuevo documento',
        edit: 'Editar documento',
        list: 'Listado de documentos',
        name: 'Nombre del documento',
        updated: 'Actualizado el documento'
      },
      details: {
        dni: 'DNI / NIE',
        photo: 'Foto del DNI / NIE',
        phone: 'Teléfonos de contacto',
        altPhone: 'Telefonos alternativos',
        altEmail: 'Email alternativos',
        address: 'Direcciones de contacto',
        birthdate: 'Fecha de nacimiento',
        cancellation_date: 'Fecha de baja',
        tshirtSize: 'Talla de camiseta',
        jacketSize: 'Talla de chaqueta',
        pantsSize: 'Talla de pantalón',
        poloSize: 'Talla de polo',
        cv: 'CV',
        comments: 'Comentarios',
        prefix: 'Prefijo del telefono',
        number: 'Numero de telefono',
        isMain: 'Contacto principal',
        idCardNumberFileUpdated: 'Fichero actualizado'
      },
      addresses: {
        address: 'Dirección',
        street: 'Calle',
        number: 'Número',
        city: 'Población',
        state: 'Provincia',
        zipCode: 'Código postal',
        country: 'País',
        extra: 'portal, escalera, etc...'
      },
      paymentOrder: {
        title: 'Pagos',
        payment: 'Pago',
        new: 'Añadir un nuevo pago',
        price: 'Precio',
        edit: 'Editar Pago',
        finalPrice: 'Precio Final',
        discount: 'Descuento',
        pendingAmount: 'Cantidad Pendiente',
        paymentPayed: 'Cantidad Pagada',
        description: 'Descripcion',
        priceMax: 'Precio máximo',
        priceMin: 'Precio mínimo',
        discountMax: 'Descuento máximo',
        discountMin: 'Descuento mínimo',
        pendingAmountMax: 'Pent. pago máxima',
        pendingAmountMin: 'Pent. pago mínima',
        paymentPayedMax: 'Cant. pagada máxima',
        paymentPayedMin: 'Cant. pagada mínima'
      },
      payment: {
        title: 'Pagos',
        add: 'Añadir Pago',
        edit: 'Editar Pago',
        state: 'Estado',
        approve: 'Aprobar',
        cancel: 'Cancelar',
        invoiceNumber: 'Número de factura',
        amount: 'importe',
        invoiceDate: 'Fecha de factura',
        paymentDate: 'Fecha de pago',
        pending: 'Pendiente',
        approved: 'Aprobado',
        canceled: 'Cancelado',
        paid: 'Pagado'
      }
    },
    user: {
      title: 'Usuarios',
      edit: 'Editar usuario',
      info: 'Información del usuario',
      lastSession: 'Última sesión'
    },
    promotions: {
      title: 'Promociones',
      new: 'Nueva promoción',
      create: 'Crear promoción',
      edit: 'Editar promoción',
      info: 'Descripción de la promoción',
      promotion: 'Promoción',
      details: {
        name: 'Nombre',
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de fin',
        comments: 'Comentarios',
        updateComments: 'Actualizar comentarios',
        status: 'Estado'
      },
      studentList: 'Listado de estudiantes',
      notStudents: 'No hay estudiantes en esta promoción',
      attendance: {
        list: 'Listado de asistencia',
        options: {
          present: 'Asiste Presencial',
          telematic: 'Asiste Online',
          absent: 'Falta'
        }
      },
      notSchedule: 'No hay cronograma asociado/activo',
      session: {
        title: 'sessiones',
        name: 'Nombre de la sesión',
        nStudents: 'Número de estudiantes',
        confirm: '¿Estás seguro que quieres borrar la sesión?'
      }
    },
    notes: {
      new: 'Nueva nota',
      create: 'Crear nota',
      update: 'Actualizar título',
      success: {
        create: 'Nota creada',
        update: 'Nota actualizada',
        delete: 'Nota eliminada'
      },
      confirm: '¿Estás seguro que quieres eliminar la nota?'
    },
    schedule: {
      title: 'Cronograma',
      new: 'Nuevo Cronograma',
      create: 'Crear Cronograma',
      configTitle: 'Configuración de un nuevo cronograma',
      notHolidayDays: 'No hay días creados',
      modulesInSchedule: 'Módulos en el cronograma',
      addModule: 'Añadir módulo',
      deleteModuleConfirm:
        '¿Estás seguro que quieres eliminar el módulo del cronograma? Todos los bloques asociados a este módulo serán liberados',
      deleteModuleInBlockConfirm: '¿Estás seguro que quieres eliminar el módulo del bloque?',
      createWeekConfirm: '¿Estás seguro que quieres crear una nueva semana?',
      deleteWeekConfirm: '¿Estás seguro que quieres eliminar la semana?',
      weekOf: 'Semana del {from} al {to}',
      hours: 'Horas',
      createDayConfirm: '¿Estás seguro que quieres crear un nuevo día?',
      cleanDay: 'Eliminar Bloques',
      cleanDayConfirm: '¡Atención! esta acción eleminará todos los bloques del día ¿Desea continuar?',
      changeDayBlocksConfiguration: 'Cambiar configuración de bloques',
      changeDayType: 'Cambiar tipo de día',
      deleteDayConfirm: '¿Estás seguro que quieres eliminar el día? Todos los datos asociados serán liberados',
      deleteDay: 'Eliminar día',
      createDay: 'Crear día',
      go: 'Ir al cronograma',
      blocks: {
        new: 'Nuevo bloque',
        createTitle: 'Bloques por defecto para los días',
        notCreated: 'No se ha creado ningún bloque',
        startTime: 'Hora de inicio',
        endTime: 'Hora de fin',
        types: {
          title: 'Tipo de bloque',
          classroom: 'Clase',
          recess: 'Descanso'
        }
      },
      days: {
        types: {
          title: 'Tipo de día',
          academic: 'Día Lectivo',
          holiday: 'Vacaciones',
          notCreated: 'Día no creado',
          daysOfHolidays: 'Días de vacaciones'
        }
      },
      validations: {
        notSameStartTime: 'La hora de inicio no puede ser igual a la hora de fin'
      },
      status: {
        state: 'Estado de Cronograma',
        draft: 'Borrador',
        approved: 'Publicado',
        approve: 'Publicar'
      }
    },
    notifications: {
      showNotRead: 'Mostrar sólo notificaciones no leídas'
    },
    leads: {
      title: 'Leads',
      new: 'Nuevo lead',
      edit: 'Editar lead',
      editStatus: 'Cambiar estado',
      info: 'Información del lead',
      list: 'Listado de leads',
      status: {
        pending: 'Pendiente',
        inProgress: 'En progreso',
        approved: 'Aprobado',
        rejected: 'Rechazado',
        1: 'Pendiente',
        2: 'En progreso',
        3: 'Aprobado',
        4: 'Rechazado'
      },
      details: {
        phone: 'Teléfono',
        source: 'Fuente',
        interested: 'Interesado',
        comments: 'Comentarios',
        document: 'Documento',
        createStudent: 'Crear estudiante',
        createStudentConfirmation: 'Se va a crear un nuevo estudiante con los datos actuales ¿Desea continuar?'
      },
      reports: {
        title: 'Reportes',
        new: 'Nuevo reporte',
        edit: 'Editar reporte'
      }
    }
  }
}

export default es
