<template>
  <el-tree :data="data" node-key="id" :class="class" :draggable="draggable" @node-drop="handleDrop">
    <template #default="{ node, data }">
      <slot name="default" v-bind="{ node, data }"></slot>
    </template>
  </el-tree>
</template>

<script setup lang="ts">
import { ElTree } from 'element-plus'
import 'element-plus/es/components/tree/style/css'

defineOptions({
  name: 'Tree',
  inheritAttrs: false,
  customOptions: {}
})

const props = defineProps<{
  data: object[]
  class?: string
  draggable?: boolean
}>()

const emits = defineEmits(['item-drop'])

const handleDrop = (node: any, item: any) => {
  const parent = searchParentNode(node.store.data, node.data.id, null)
  emits('item-drop', node.data, parent)
}

const searchParentNode = (elements: any, id: number, parent: object | null = null): object | null => {
  for (const element of elements) {
    if (element.id === id) {
      return parent
    } else if (element.children) {
      let result = searchParentNode(element.children, id, element)
      if (result) {
        return result
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-tree {
  --el-tree-expand-icon-color: var(--primary-color);
}
</style>
<style lang="scss">
.el-tree {
  .el-tree-node__content {
    border-radius: $border-radius;
  }
}
</style>
