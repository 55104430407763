import { BlobDocument, Uuid } from 'meya'
import { BaseService } from '@service/common/base.services'
import { User } from '@entity/backoffice/account/user.entity'

export class UserService extends BaseService {
  protected static apiDefaultSearchPath: string = '/v1/backoffice/account/user/search'

  static create(name: string, surname: string, email: string, roleId: Uuid, password?: string): Promise<User> {
    const form = {
      name,
      email,
      surname,
      role_id: roleId.getValue(),
      password
    }
    return this.post(`/v1/backoffice/account/user`, form).then(data => User.fromAPI(data))
  }

  static update(
    id: Uuid,
    name: string,
    surname: string,
    email: string,
    language: string,
    roleId?: Uuid
  ): Promise<User> {
    const form = {
      name,
      email,
      surname,
      role_id: roleId?.getValue(),
      language
    }

    return this.put(`/v1/backoffice/account/user/${id.getValue()}`, form).then(data => User.fromAPI(data))
  }

  static remove(id: Uuid): Promise<User> {
    return this.delete(`/v1/backoffice/account/user/${id.getValue()}`, null).then(data => User.fromAPI(data))
  }

  static getById(id: Uuid): Promise<User> {
    return this.get(`/v1/backoffice/account/user/${id.getValue()}`).then(data => User.fromAPI(data))
  }

  static changePassword(id: Uuid, lastPassword: string, newPassword: string): Promise<void> {
    const form = {
      password: lastPassword,
      new_password: newPassword
    }
    return this.put(`/v1/backoffice/account/user/${id.getValue()}/changePassword`, form)
  }

  static recovery(email: string): Promise<void> {
    return this.post(`/v1/backoffice/account/user/recover`, { email }, { public: true }).then(data => {
      console.log('recovery', data)
    })
  }
  static getProfileImage(id: Uuid): Promise<BlobDocument> {
    return this.get(`/v1/backoffice/account/user/${id.getValue()}/picture`, {
      responseType: 'blob',
      hideError: true
    }).then(data => new BlobDocument(data))
  }

  static updateProfileImage(id: Uuid, image: File | Blob): Promise<User> {
    const formData = new FormData()
    formData.append('file', image)
    return this.post(`/v1/backoffice/account/user/${id.getValue()}/picture`, formData).then(data => User.fromAPI(data))
  }
}
