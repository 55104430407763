<template>
  <v-timeline :side="props.side" icon-color="primary" :icon-path="iconPath" align="start">
    <v-timeline-item v-for="(item, idx) in items" :key="idx" :dot-color="'#ffffff'" :icon="resolveIconPath(item)">
      <slot name="item" :item="item"></slot>
    </v-timeline-item>
  </v-timeline>
</template>

<script setup lang="ts">
defineOptions({
  name: 'Timeline',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  iconPath: string
  items: []
  side: 'start' | 'end'
}

const props = withDefaults(defineProps<PropsType>(), {
  iconPath: 'icon'
})
const resolveIconPath = item => {
  if (!props.iconPath) {
    return item.icon
  }

  const parts = props.iconPath.split('.')
  let value = item
  for (const part of parts) {
    value = value[part]
  }
  return value
}
</script>

<style lang="scss">
.v-timeline--vertical.v-timeline--align-start {
  justify-content: flex-start;
}
</style>
