<template>
  <v-tabs v-model="tab" :color="color">
    <slot></slot>
  </v-tabs>
</template>

<script setup lang="ts">
defineOptions({
  name: 'Tabs',
  inheritAttrs: false,
  customOptions: {}
})

defineProps<{
  color?: string
}>()

const tab = defineModel()
</script>
