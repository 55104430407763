import { RouteRecordRaw } from 'vue-router'
import { PermissionList } from '@application/permisionList'

const paths: Array<RouteRecordRaw> = [
  {
    path: 'setting',
    name: 'setting-main',
    component: () => import('@vBackoffice/setting/MainSetting.vue'),
    children: [
      {
        path: 'main',
        name: 'setting',
        meta: { permission: PermissionList.ACCOUNT_USERS_VIEW },
        component: () => import('@vBackoffice/setting/SettingView.vue')
      },
      {
        path: 'userAdmin',
        name: 'user-admin',
        meta: { permission: PermissionList.ACCOUNT_USERS_VIEW },
        component: () => import('@vBackoffice/setting/UserAdmin.vue')
      },
      {
        path: 'rolesAdmin',
        name: 'roles-admin',
        meta: { permission: PermissionList.ACCOUNT_USERS_VIEW },
        component: () => import('@vBackoffice/setting/roles/RolesAdmin.vue')
      },
      {
        path: 'rolesAdmin/rolesUpdate/:id',
        name: 'roles-update',
        meta: { permission: PermissionList.ACCOUNT_USERS_VIEW },
        component: () => import('@vBackoffice/setting/roles/RolesUpdate.vue')
      },
      {
        path: 'leadReportTypesAdmin',
        name: 'lead-report-types-admin',
        meta: { permission: PermissionList.LEADS_VIEW },
        component: () => import('@vBackoffice/setting/LeadReportTypesAdmin.vue')
      },
      {
        path: 'apiKeysAdmin',
        name: 'api-keys-admin',
        meta: { permission: PermissionList.API_KEYS_VIEW },
        component: () => import('@vBackoffice/setting/apiKeys/ApiKeysAdmin.vue')
      },
      {
        path: 'apiKeysAdmin/apiKeysUpdate/:id',
        name: 'api-keys-update',
        meta: { permission: PermissionList.API_KEYS_UPDATE },
        component: () => import('@vBackoffice/setting/apiKeys/ApiKeysUpdate.vue')
      }
    ]
  }
]

export default paths
