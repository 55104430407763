<template>
  <v-list-item
    :value="value"
    :title="title"
    :subtitle="subtitle"
    :prepend-icon="prependIcon"
    :prepend-avatar="prependAvatar"
    :append-icon="appendIcon"
    :append-avatar="appendAvatar"
    :class="class"
    v-bind="$attrs"
    @click="emit('click')"
  >
    <slot></slot>

    <template #append v-if="!appendIcon && !appendAvatar">
      <slot name="append"></slot>
    </template>
    <template #prepend v-if="!prependIcon && !prependAvatar">
      <slot name="prepend"></slot>
    </template>
  </v-list-item>
</template>

<script setup lang="ts">
defineOptions({
  name: 'ListItem',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  value?: string
  title?: string
  subtitle?: string
  prependIcon?: string
  prependAvatar?: string
  appendIcon?: string
  appendAvatar?: string
  class?: string | string[] | object
}

const props = defineProps<PropsType>()
const emit = defineEmits(['click'])
</script>
