<template>
  <v-breadcrumbs :items="items">
    <template #item="{ item }">
      <span class="item" @click="itemClick(item)">
        {{ item.title }}
      </span>
    </template>
  </v-breadcrumbs>
</template>

<script setup lang="ts">
defineOptions({
  name: 'Breadcrumb',
  inheritAttrs: false,
  customOptions: {}
})

defineProps<{
  items: []
}>()

const emit = defineEmits(['item-click'])

const itemClick = (item: any) => {
  emit('item-click', item)
}
</script>

<style lang="scss" scoped>
.item {
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
