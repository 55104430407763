import { RouteRecordRaw } from 'vue-router'
import { PermissionList } from '@application/permisionList'

const paths: Array<RouteRecordRaw> = [
  {
    path: 'promotions',
    name: 'promotion-main',
    component: () => import('@vBackoffice/promotion/MainPromotion.vue'),
    children: [
      {
        path: '',
        name: 'promotions-list',
        meta: { permission: PermissionList.PROMOTIONS_VIEW },
        component: () => import('@vBackoffice/promotion/ListPromotion.vue')
      },
      {
        path: ':id',
        name: 'promotions-detail',
        meta: { permission: PermissionList.PROMOTIONS_VIEW },
        component: () => import('@vBackoffice/promotion/DetailsPromotion.vue')
      },
      {
        path: ':id/practice/:practiceId',
        name: 'promotions-practice-detail',
        meta: { permission: PermissionList.PROMOTIONS_VIEW },
        component: () => import('@vBackoffice/promotion/DetailsPromotion.vue')
      },

      // SESSIONS
      {
        path: ':id/module/:promoModuleId',
        name: 'promo-module-practice',
        meta: { permission: PermissionList.PROMOTIONS_VIEW },
        component: () => import('@vBackoffice/promotion/session/promoModulePractice/ListPromoModulePractice.vue')
      },
      {
        path: ':id/module/:promoModuleId/practice/:practiceId',
        name: 'promo-groups',
        meta: { permission: PermissionList.PROMOTIONS_VIEW },
        component: () => import('@vBackoffice/promotion/session/promoModulePractice/ListPromoModulePracticeGroup.vue')
      },
      // SCHEDULES
      {
        path: ':id/schedule/new',
        name: 'schedule-new',
        meta: { permission: PermissionList.PROMOTIONS_VIEW },
        component: () => import('@vBackoffice/promotion/schedule/NewSchedule.vue')
      },
      {
        path: ':id/schedule/:scheduleId',
        name: 'schedule-detail',
        meta: { permission: PermissionList.PROMOTIONS_VIEW },
        component: () => import('@vBackoffice/promotion/schedule/DetailSchedule.vue')
      }
    ]
  }
]

export default paths
