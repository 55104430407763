<template>
  <div class="lca-input-phone">
    <div class="prefix">
      <v-autocomplete
        v-model="phonePrefix"
        :items="prefix"
        item-value="dial_code"
        item-title="name"
        :disabled="disabled"
        :label="$t('core.countryPrefix')"
        :autoSelectFirst="true"
        :hide-details="true"
        @update:modelValue="renderModel"
        variant="underlined"
        density="compact"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="`(${item.raw.dial_code}) ${item.raw.name}`" density="compact">
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>

    <v-text-field
      ref="phone-input"
      v-model="phoneNumber"
      :label="label"
      density="compact"
      :clearable="clearable"
      :disabled="disabled"
      @keypress="handleInputNumber"
      @update:modelValue="renderModel"
    >
    </v-text-field>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import en from './locale/en.json'

defineOptions({
  name: 'InputPhone',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  label?: string
  clearable?: boolean
  disabled?: boolean
  prefix?: string
}
const props = defineProps<PropsType>()
const phoneValue = defineModel<string>()

const phonePrefix = ref<string>('+34') // TODO: change to i18n
const phoneNumber = ref<string>('')

const renderModel = () => {
  if(phoneNumber.value && phoneNumber.value.trim().length > 0 ){
    phoneValue.value = `${phonePrefix.value ?? ''}${phoneNumber.value ?? ''}`
  }else{
    phoneValue.value = ''
  }

}

const handleInputNumber = event => {
  const key = event.charCode
  if (!(key >= 48 && key <= 57)) {
    event.preventDefault()
  }
}

const prefix = en.map(item => ({ ...item, text: `${item.flag} (${item.dial_code})` })) // TODO: change to i18n

onBeforeMount(() => {
  phoneNumber.value = phoneValue.value
  if (props.prefix && props.prefix.trim().length > 0) {
    phonePrefix.value = props.prefix
  }
  renderModel()
})
</script>

<style lang="scss" scoped>
.lca-input-phone {
  display: flex;
  justify-content: flex-start;

  .prefix {
    width: 90px;
    margin-right: 10px;
  }
}
</style>
