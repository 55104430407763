<template>
  <v-expansion-panel
    :color="type"
    :disabled="disabled"
    :text="text"
    :title="slots.title ? undefined : title"
    :value="value"
  >
    <v-expansion-panel-title :static="true" v-if="slots.title">
      <slot name="title"></slot>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <slot></slot>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

defineOptions({
  name: 'ExpansionPanel',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  type?: string
  disabled?: boolean
  text?: string
  title?: string
  value?: any
}

const props = defineProps<PropsType>()
const slots = useSlots()
</script>

<style lang="scss">
.v-expansion-panel-title {
  padding: 8px 16px;

  .v-icon--size-default {
    font-size: calc(var(--v-icon-size-multiplier) * 1em);
  }
}
.v-expansion-panel {
  &.v-expansion-panel--active {
    > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
      min-height: auto;
    }
  }
}
</style>
