import type { App, Plugin, Component } from 'vue'

import { Components } from './components/index'
import { createVuetify } from './plugins/vuetify'
import { createI18n } from './plugins/i18n'

export type * from './application/interfaces/meya.interfaces'
import type { MeyaOptions } from './application/interfaces/meya.interfaces'

import { createNotify } from './plugins/notify'

export const meya: Plugin = {
  install(app: App, options: MeyaOptions): void {
    app.use(createVuetify(options?.theme))
    app.use(createI18n(options?.i18n))
    app.provide('$notify', createNotify(app, options?.notify))

    // Instanciar los componentes
    for (const index of Object.keys(Components)) {
      const component = Components[index as keyof Object] as Component

      const prefix = options?.core?.prefix ?? 'lca-'

      const name: string = component.name ?? ''
      if (name != '') {
        const nameComponent: string = prefix + name.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
        app.component(nameComponent, component)
      }
    }
  }
}

export * from './application/services'
export * from './plugins'
export * from './application/valueObjects'
export * from './application/utils'
