import { Uuid } from 'meya'
import { BaseService } from '@service/common/base.services'
import { Note, NoteAPIResponse } from '@entity/backoffice/account/note.entity'
import { useUserStore } from '@/store/account/user.store'

export class NotesService extends BaseService {
  static create(id: Uuid, title: string): Promise<void> {
    return this.post(`/v1/backoffice/account/user/${id.getValue()}/note`, { title }).then(data => {
      const userStore = useUserStore()
      if (data.notes.length > 0) {
        const notes = data.notes.map((note: NoteAPIResponse) => Note.fromAPI(note))
        userStore.loadNotes(notes)
      }
    })
  }
  static remove(id: Uuid, noteId: Uuid): Promise<void> {
    return this.delete(`/v1/backoffice/account/user/${id.getValue()}/note/${noteId.getValue()}`, null).then(data => {
      const userStore = useUserStore()
      if (data.notes.length > 0) {
        const notes = data.notes.map((note: NoteAPIResponse) => Note.fromAPI(note))
        userStore.loadNotes(notes)
      }
    })
  }

  static update(id: Uuid, noteId: Uuid, title: string, text: string): Promise<void> {
    return this.put(`/v1/backoffice/account/user/${id.getValue()}/note/${noteId.getValue()}`, { title, text }).then(
      data => {
        const userStore = useUserStore()
        if (data.notes.length > 0) {
          const notes = data.notes.map((note: NoteAPIResponse) => Note.fromAPI(note))
          userStore.loadNotes(notes)
        }
      }
    )
  }
}
