//Custom
import DocumentViewer from './custom/viewer/DocumentViewer.vue'
import InputColorPicker from './custom/InputColorPicker.vue'
import InputDatePicker from './custom/InputDatePicker.vue'
import InputIconPicker from './custom/InputIconPicker/InputIconPicker.vue'
import InputPhone from './custom/inputPhone/InputPhone.vue'
import InputMoney from './custom/InputMoney.vue'
import InputTimePicker from './custom/InputTimePicker.vue'
import Image from './custom/Image.vue'
import Modal from './custom/Modal.vue'
// Custom - Sections
import CardSection from './custom/sections/CardSection.vue'
import HeaderSection from './custom/sections/HeaderSection.vue'

// Elements
import Avatar from './elements/Avatar.vue'
import Badge from './elements/Badge.vue'
import Breadcrumb from './elements/Breadcrumb.vue'
import Button from './elements/Button.vue'
import ButtonGroup from './elements/ButtonGroup.vue'
import Card from './elements/Card.vue'
import Chip from './elements/Chip.vue'
import Dialog from './elements/Dialog.vue'
import Divider from './elements/Divider.vue'
import ExpansionPanel from './elements/ExpansionPanel.vue'
import ExpansionPanels from './elements/ExpansionPanels.vue'
import List from './elements/List.vue'
import ListGroup from './elements/ListGroup.vue'
import ListItem from './elements/ListItem.vue'
import Loading from './elements/Loading.vue'
import Menu from './elements/Menu.vue'
import Skeleton from './elements/Skeleton.vue'
import Switch from './elements/Switch.vue'
import Tab from './elements/Tab.vue'
import Tabs from './elements/Tabs.vue'
import Timeline from './elements/Timeline.vue'
import Tooltip from './elements/Tooltip.vue'
import Tree from './elements/Tree.vue'

// Form
import Checkbox from './form/Checkbox.vue'
import DatePicker from './form/DatePicker.vue'
import FileInput from './form/FileInput.vue'
import Form from './form/Form.vue'
import Input from './form/Input.vue'
import Select from './form/Select.vue'
import TextArea from './form/TextArea.vue'
import TimePicker from './form/TimePicker.vue'

// Helpers
import FadeTransition from './helpers/FadeTransition.vue'

// Layout
import App from './layout/App.vue'
import AppBar from './layout/AppBar.vue'
import Col from './layout/Col.vue'
import Container from './layout/Container.vue'
import NavigationDrawer from './layout/NavigationDrawer.vue'
import Row from './layout/Row.vue'

// Tables
import DataTable from './tables/DataTable.vue'
import DataTableServer from './tables/DataTableServer.vue'

// Export
export const Components = {
  // Custom
  DocumentViewer,
  InputColorPicker,
  InputDatePicker,
  InputIconPicker,
  InputPhone,
  InputMoney,
  InputTimePicker,
  Image,
  Modal,

  // Custom - Sections
  CardSection,
  HeaderSection,

  // Elements
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Chip,
  Dialog,
  Divider,
  ExpansionPanel,
  ExpansionPanels,
  List,
  ListGroup,
  ListItem,
  Loading,
  Menu,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  Timeline,
  Tooltip,
  Tree,

  // Form
  Checkbox,
  DatePicker,
  FileInput,
  Form,
  Input,
  Select,
  TextArea,
  TimePicker,

  // Helpers
  FadeTransition,

  // Layout
  App,
  AppBar,
  Col,
  Container,
  NavigationDrawer,
  Row,

  // Tables
  DataTable,
  DataTableServer
}
