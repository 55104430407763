<template>
  <v-text-field
    v-model="model"
    :active="show"
    :focused="show"
    :label="label"
    prepend-icon="fa-regular fa-clock"
    readonly
    density="compact"
    clearable
    :disabled="disabled"
    :rules="rules"
  >
    <v-dialog v-model="show" activator="parent" width="auto">
      <v-time-picker
        v-if="show"
        v-model="model"
        :format="format"
        :title="title"
        :hide-header="hideHeader"
        :min="min"
        :max="max"
      ></v-time-picker>
    </v-dialog>
  </v-text-field>
</template>

<script setup lang="ts">
import { ref } from 'vue'

defineOptions({
  name: 'InputTimePicker',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  label?: string
  format?: 'ampm' | '24hr'
  hideHeader?: boolean | undefined
  title?: string
  min?: string
  max?: string
  disabled?: boolean
  rules?: any[]
}
const props = withDefaults(defineProps<PropsType>(), {
  title: '',
  format: '24hr'
})

const model = defineModel()
const show = ref(false)
</script>
