import { RouteRecordRaw } from 'vue-router'

const paths: Array<RouteRecordRaw> = [
  {
    path: 'promotions',
    name: 'student-promotion-main',
    component: () => import('@vStudent/promotion/MainPromotion.vue'),
    children: [
      {
        path: '',
        name: 'student-promotions-list',
        component: () => import('@vStudent/promotion/ListPromotion.vue')
      },
      {
        path: ':id',
        name: 'student-promotions-detail',
        component: () => import('@vStudent/promotion/DetailsPromotion.vue')
      }
    ]
  }
]

export default paths
