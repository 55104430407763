<template>
  <v-text-field
    v-model="model"
    :label="label"
    :type="type"
    :disabled="disabled"
    :readonly="readonly"
    :placeholder="placeholder"
    :clearable="clearable"
    :rules="rules"
    :validate-on="validateOn"
    :append-icon="appendIcon"
    :append-inner-icon="appendInnerIcon"
    :prepend-icon="prependIcon"
    :prepend-inner-icon="prependInnerIcon"
    :variant="variant"
    :density="density"
    @click:clear="emit('clear')"
    v-bind="$attrs"
  >
    <slot></slot>
    <template #prepend v-if="slots.prepend">
      <slot name="prepend"></slot>
    </template>
    <template #append v-if="slots.append">
      <slot name="append"></slot>
    </template>
  </v-text-field>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

defineOptions({
  name: 'Input',
  inheritAttrs: false,
  customOptions: {}
})

interface PropsType {
  label?: string
  type?: string
  rules?: any
  disabled?: boolean
  readonly?: boolean
  placeholder?: string
  clearable?: boolean
  validateOn?:
    | 'blur'
    | 'input'
    | 'submit'
    | 'blur lazy'
    | 'input lazy'
    | 'submit lazy'
    | 'lazy blur'
    | 'lazy input'
    | 'lazy submit'
    | 'lazy'
  appendIcon?: string
  appendInnerIcon?: string
  prependIcon?: string
  prependInnerIcon?: string
  density?: 'default' | 'comfortable' | 'compact' | undefined
  variant?: 'underlined' | 'outlined' | 'filled' | 'solo' | 'solo-inverted' | 'solo-filled' | 'plain'
}

const props = withDefaults(defineProps<PropsType>(), {
  density: 'compact'
})

const model = defineModel()
const slots = useSlots()

const emit = defineEmits(['clear'])
</script>
<style lang="scss">
.v-input__append {
  .v-btn {
    .v-btn__content {
      i {
        margin: 0 auto;
      }
    }
  }
}
</style>
