<template>
  <v-dialog
    v-model="show"
    persistent
    :transition="options?.transition"
    :width="options?.width"
    scrollable
    v-bind="options.dialogProps"
  >
    <v-card>
      <div class="body-confirm">
        {{ text }}
      </div>

      <v-card-actions>
        <div class="btns">
          <v-btn color="error" @click="onCancel" v-bind="options.secondaryButtonProps || options.buttonProps">
            {{ btnTitleCancel }}
          </v-btn>

          <v-btn
            color="success"
            variant="tonal"
            @click="onSubmit"
            v-bind="options.primaryButtonProps || options.buttonProps"
          >
            {{ btnTitleSuccess }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { PropType, computed, ref, getCurrentInstance } from 'vue'

const _instance = getCurrentInstance()

const props = defineProps({
  content: {
    type: [String, Object],
    required: true,
    default: 'Are you sure?'
  },
  options: {
    type: Object,
    required: true
  },
  status: {
    type: String as PropType<'default' | 'success' | 'error' | 'warning' | 'info'>,
    required: true
  },
  onSubmit: {
    type: Function,
    required: true
  },
  onCancel: {
    type: Function,
    required: true
  }
})

const btnTitleSuccess = computed((): string => {
  const key = props.options.successButtonText ?? 'Continue'
  return _instance.appContext.config.globalProperties.$t(key)
})

const btnTitleCancel = computed((): string => {
  const key = props.options.cancelButtonText ?? 'Cancel'
  return _instance.appContext.config.globalProperties.$t(key)
})

const show = ref(true)
//const title = computed(() => typeof props.content === 'object' ? props.content?.title : undefined);
const text = computed(() => (typeof props.content === 'object' ? props.content?.text : props.content))

const onSubmit = () => {
  show.value = false
  props.onSubmit(true)
}

const onCancel = () => {
  show.value = false
  props.onCancel()
}
</script>

<style lang="scss" scoped>
.body-confirm {
  padding: 20px;
  text-align: center;
}
.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
