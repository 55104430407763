<template>
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items-length="itemsLength"
    :items="items"
    :loading="loading"
    :search="search"
    :multi-sort="multiSort"
    :items-per-page-text="itemsPerPageText"
    :hover="hover"
    :items-per-page-options="itemsPerPageOptions"
    :show-current-page="showCurrentPage"
    :density="density"
    :sort-by="sortBy"
    v-bind="$attrs"
  >
    <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ value }">
      <slot :name="`item.${header.key}`" :value="value">{{ value }}</slot>
    </template>
  </v-data-table-server>
</template>

<script setup lang="ts">
defineOptions({
  name: 'DataTableServer',
  inheritAttrs: false,
  customOptions: {}
})

const itemsPerPage = defineModel<string | number>()

interface PropsType {
  headers?: object
  itemsLength?: number | string | undefined
  items: any[]
  loading?: string | boolean
  search?: string
  multiSort?: boolean
  itemsPerPageText?: string
  hover?: boolean
  itemsPerPageOptions?: (number | { title: string; value: number })[]
  showCurrentPage?: boolean
  density?: 'default' | 'comfortable' | 'compact'
  sortBy?: object[]
}

const props = defineProps<PropsType>()
</script>
