import { ValueObject } from './valueObject'

export class Money extends ValueObject<number> {
  constructor(
    public readonly amount: number,
    public readonly currency: string = 'EUR'
  ) {
    super(amount)
  }

  public static fromFloat(amount: number, currency: string = 'EUR'): Money {
    return new Money(amount, currency)
  }

  public getAmount(): number {
    return this.amount
  }

  public getCurrency(): string {
    return this.currency
  }

  public toJSON(): object {
    return {
      amount: this.amount,
      currency: this.currency
    }
  }
}
