<template>
  <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" v-bind="$attrs">
    <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ value, item }">
      <slot :name="`item.${header.key}`" :value="value" :item="item">{{ value }} </slot>
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
defineOptions({
  name: 'DataTable',
  inheritAttrs: false,
  customOptions: {}
})

const itemsPerPage = defineModel<string | number>()

interface PropsType {
  headers?: object
  items: any[]
  loading?: string | boolean
  search?: string
}

const props = defineProps<PropsType>()
</script>
